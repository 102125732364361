import type React from 'react';

const ConfirmSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.277 45.437C114.955 48.1149 114.955 52.4566 112.277 55.1345L69.9913 97.4202C67.3135 100.098 62.9717 100.098 60.2939 97.4202C57.616 94.7423 57.616 90.4006 60.2939 87.7227L102.58 45.437C105.257 42.7591 109.599 42.7591 112.277 45.437Z"
        fill="white"
        className="svg-color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.9913 97.4202C67.3135 100.098 62.9717 100.098 60.2939 97.4202L35.151 72.2773C32.4731 69.5994 32.4731 65.2577 35.151 62.5799C37.8289 59.902 42.1706 59.902 44.8485 62.5799L69.9913 87.7227C72.6692 90.4006 72.6692 94.7423 69.9913 97.4202Z"
        fill="white"
        className="svg-color"
      />
    </svg>
  );
};

export default ConfirmSvg;
