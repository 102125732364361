import type React from 'react';

const InfoSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M78.4002 40C78.4002 43.5346 75.5349 46.4 72.0002 46.4C68.4656 46.4 65.6002 43.5346 65.6002 40C65.6002 36.4654 68.4656 33.6 72.0002 33.6C75.5349 33.6 78.4002 36.4654 78.4002 40Z"
        fill="white"
      />
      <path
        d="M78.4002 59.2C78.4002 55.6654 75.5349 52.8 72.0002 52.8H59.2002C55.6656 52.8 52.8002 55.6654 52.8002 59.2C52.8002 62.7347 55.6656 65.6 59.2002 65.6H65.6002V97.6H59.2002C55.6656 97.6 52.8002 100.465 52.8002 104C52.8002 107.535 55.6656 110.4 59.2002 110.4H84.8002C88.3349 110.4 91.2002 107.535 91.2002 104C91.2002 100.465 88.3349 97.6 84.8002 97.6H78.4002V59.2Z"
        fill="white"
      />
    </svg>
  );
};

export default InfoSvg;
