import type React from 'react';

const LobbySvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.238 108.571C45.3445 108.571 43.8095 107.036 43.8095 105.143V76.8831C43.8095 74.9895 42.2744 73.4545 40.3809 73.4545H34.6715C31.6018 73.4545 30.0791 69.7301 32.2695 67.5794L69.5979 30.9298C70.9316 29.6203 73.0683 29.6203 74.402 30.9298L111.73 67.5794C113.921 69.7301 112.398 73.4545 109.328 73.4545H103.619C101.725 73.4545 100.19 74.9895 100.19 76.8831V105.143C100.19 107.036 98.6554 108.571 96.7618 108.571H91.4285C89.5349 108.571 87.9999 107.036 87.9999 105.143V86.8571C87.9999 84.9636 86.4649 83.4285 84.5714 83.4285H70.8571C68.9635 83.4285 67.4285 84.9636 67.4285 86.8571V105.143C67.4285 107.036 65.8935 108.571 63.9999 108.571H47.238Z"
        fill="white"
      />
    </svg>
  );
};

export default LobbySvg;
