import type React from 'react';

const StartSvg = () => {
  return (
    <svg width="272" height="112" viewBox="0 0 272 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="10" width="252" height="92" rx="46" fill="#FFD600" stroke="#060404" strokeWidth="4" />
      <path
        d="M168 59.4649C170.667 57.9247 170.667 54.0753 168 52.5351L123 26.5393C120.332 24.9978 117 26.9258 117 30.0041L117 81.9958C117 85.0742 120.332 87.0022 123 85.4607L168 59.4649Z"
        fill="white"
        stroke="#060404"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StartSvg;
