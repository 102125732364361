import type React from 'react';

const SkipLeftSvg = (): JSX.Element => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M60.5712 49.1429C60.5712 45.3558 57.5012 42.2858 53.7141 42.2858C49.927 42.2858 46.8569 45.3558 46.8569 49.1429V94.8572C46.8569 98.6443 49.927 101.714 53.7141 101.714C57.5012 101.714 60.5712 98.6443 60.5712 94.8572V49.1429Z"
        fill="white"
      />
      <path
        d="M90.0082 44.2942C92.6861 41.6163 97.0278 41.6163 99.7057 44.2942C102.384 46.9721 102.384 51.3138 99.7057 53.9916L81.6973 72L99.7057 90.0085C102.384 92.6863 102.384 97.028 99.7057 99.7059C97.0278 102.384 92.6861 102.384 90.0082 99.7059L67.1511 76.8488C64.4732 74.1709 64.4732 69.8292 67.1511 67.1513L90.0082 44.2942Z"
        fill="white"
      />
    </svg>
  );
};

export default SkipLeftSvg;
