import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, PopupOpeningTypes } from '../../global.d';
import { setBrokenBuyFeature, setBrokenGame, setIsAutoSpins, setIsPopupOpeningInProgress } from '../../gql';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isMobileLandscape, isMobilePortrait, isTabletLandscape, isTabletPortrait } from '../../utils';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/container';
import {
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';

class BuyFeatureBtn extends ViewContainer {
  private btn: PIXI.Sprite;

  private text: TextField;

  isDisabled: boolean;

  resizedWidth: number;

  resizedHeight: number;

  constructor() {
    super();
    this.x = -750;
    this.y = 0;
    this.isDisabled = setBrokenBuyFeature();
    this.visible = !setBrokenGame();
    this.resizedWidth = 0;
    this.resizedHeight = 0;
    this.text = this.initBuyFeatureText();
    this.btn = this.initBuyFeatureBtn();
    this.addChild(this.btn);

    // this.handleDisable(true);
    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, (isVisible: boolean) => (this.visible = !isVisible));
    eventManager.on(EventTypes.RESIZE, this.resize.bind(this));
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.buttonMode = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnHover);
        this.text.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnPressed);

        this.text.text.style = buyFeatureClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);

        this.text.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): TextField {
    const text = new TextField(i18n.t<string>('buyFeatureBtn'), 110, 300, buyFeatureTextStyle);

    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, this.height / 2);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      AudioApi.play({ type: ISongs.SFX_BuyFeature });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.OPEN_BACKDROP_BG);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    this.btn.buttonMode = !disable;
    if (disable) {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnNotActive);
      this.text.text.style = buyFeatureDisableTextStyle;
    } else {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
      this.text.text.style = buyFeatureTextStyle;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  protected override resize(_width: number, _height: number): void {
    this.scale.set(1.3);
    this.y = 0;
    this.x = -775;

    if (isTabletLandscape(_width, _height)) {
      this.scale.set(1.5);
      this.x = -835;
      this.y = 0;
    }
    if (isTabletPortrait(_width, _height)) {
      this.scale.set(1.5);
      this.y = 620;
      this.x = 590;
    }
    if (isMobilePortrait(_width, _height)) {
      this.scale.set(1.8);
      this.y = 650;
      this.x = 480;
    }
    if (isMobileLandscape(_width, _height)) {
      this.scale.set(1.5);
      this.x = -900;
    }
  }
}

export default BuyFeatureBtn;
