import { Colors } from '../../config/variables';
import { EventTypes } from '../../global.d';
import { setIsTurboSpin } from '../../gql/cache';
import { calcPercentage } from '../../utils';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class TurboSpinBtn extends UiButton {
  buyFeatureOpened = false;
  constructor() {
    super(setIsTurboSpin() ? 'turbospin-on' : 'turbospin-off');
    this.btn.tint = setIsTurboSpin() ? Colors.GAME_COLOR : Colors.WHITE_COLOR;
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.TOGGLE_TURBO_SPIN, (_isTurboSpin: boolean) => {
      this.handleUpdateIntent();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.buyFeatureOpened = true;
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.buyFeatureOpened = false;
      this.handleDisable();
    });
  }

  public override handleDisable(): void {
    this.setDisable(this.buyFeatureOpened);
  }

  private handleClick(): void {
    if (!this.isDisabled) {
      setIsTurboSpin(!setIsTurboSpin());
      this.handleUpdateIntent();
    }
  }

  private handleUpdateIntent(): void {
    if (setIsTurboSpin()) {
      this.btn.tint = Colors.GAME_COLOR;
      this.updateIntent('turbospin-on');
    } else {
      this.btn.tint = Colors.WHITE_COLOR;
      this.updateIntent('turbospin-off');
    }
  }

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);

    let x = this.applicationSize.width - gap - size;
    let y = gap;

    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      this.btn.anchor.set(0, 1);
      x = gap;
      y = this.applicationSize.height - gap;
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default TurboSpinBtn;
