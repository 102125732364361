import i18n from 'i18next';
import * as _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureTypes, IBalance, IBonus } from '../../global.d';
import { setBetAmount, setCoinAmount, setCurrency, setIsBuyFeaturePopupOpened, setSlotConfig } from '../../gql';
import { IBetSettings } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { normalizeCoins, showCurrency } from '../../utils';
import { getBetsSetting } from '../../utils/fromFragment';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { eventManager } from '../config';

import BuyFeaturePopupConfirm from './buyFeaturePopupConfirm';
import {
  amountTextStyle,
  betValueStyle,
  buyFeatureTitleStyle,
  totalCostTextAmountStyle,
  totalCostTextStyle,
} from './textStyles';

class BuyFeaturePopup extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: TextField;

  private totalCostText: TextField;

  private totalCostTextAmount: TextField;

  private amountText: TextField;

  private minusBtn: PIXI.Sprite;

  private plusBtn: PIXI.Sprite;

  private input: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private linesAmount: number;

  private currency = 'FUN';

  private betValue: TextField;

  private buyFeaturePopupConfirm: BuyFeaturePopupConfirm;

  isNoFunds: boolean;

  balance: number;

  constructor(lines: number[][]) {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = lines.length;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.popupBg = this.initPopupBg();

    this.titleText = this.initTitle();

    this.amountText = this.initAmountText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.input = this.initInput();
    this.betValue = this.initBetValue();

    this.totalCostText = this.initTotalCostText();
    this.totalCostTextAmount = this.initTotalCostTextAmount();

    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();

    this.buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      setIsBuyFeaturePopupOpened(true);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BACKDROP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: IBalance) => {
      this.balance = balance.amount / 100;
      this.handleDisable();
    });
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.onCancel();
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.popupBg.addChild(this.titleText.getText());
    this.popupBg.addChild(this.totalCostText.getText());
    this.popupBg.addChild(this.totalCostTextAmount.getText());
    this.popupBg.addChild(this.amountText.getText());
    this.popupBg.addChild(this.minusBtn);
    this.popupBg.addChild(this.plusBtn);
    this.popupBg.addChild(this.input);
    this.popupBg.addChild(this.betValue.getText());
    this.popupBg.addChild(this.okBtn);
    this.popupBg.addChild(this.cancelBtn);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));
    popupBg.anchor.set(0.5);

    return popupBg;
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyFeatureTitle', { spinsNumber: 10 }),
      400,
      100,
      buyFeatureTitleStyle as Partial<PIXI.ITextStyle>,
    );
    title.text.name = 'title';
    title.text.y = -160;
    title.text.x = 0;
    title.text.anchor.set(0.5);

    return title;
  }

  private initTotalCostText(): TextField {
    const totalCostText = new TextField(
      i18n.t<string>('freeSpins.confirmBanner.cost'),
      400,
      100,
      totalCostTextStyle as Partial<PIXI.ITextStyle>,
    );
    totalCostText.text.name = 'totalCostText';
    totalCostText.text.y = 30;
    totalCostText.text.x = 0;
    totalCostText.text.anchor.set(0.5);

    return totalCostText;
  }

  private initTotalCostTextAmount(): TextField {
    const betValue = new TextField(this.getTotalCost(), 300, 100, totalCostTextAmountStyle as Partial<PIXI.ITextStyle>);
    betValue.text.name = 'totalCostValue';
    betValue.text.y = 50;
    betValue.text.x = 0;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initAmountText(): TextField {
    const betAmountTitle = new TextField(
      i18n.t<string>('buyFeatureBetPerGame'),
      400,
      100,
      amountTextStyle as Partial<PIXI.ITextStyle>,
    );
    betAmountTitle.text.name = 'betAmountTitle';
    betAmountTitle.text.y = -95;
    betAmountTitle.text.x = 0;
    betAmountTitle.text.anchor.set(0.5);

    return betAmountTitle;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnPressed),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleMinus.bind(this),
      onTouchStart: this.handleMinus.bind(this),
    });
    minusBtn.name = 'minusBtn';
    minusBtn.y = -45;
    minusBtn.x = -104;
    minusBtn.anchor.set(1, 0.5);

    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnPressed),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handlePlus.bind(this),
      onTouchStart: this.handlePlus.bind(this),
    });
    plusBtn.name = 'plusBtn';
    plusBtn.y = -45;
    plusBtn.x = 104;
    plusBtn.anchor.set(0, 0.5);

    return plusBtn;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureInput));

    input.y = -45;
    input.x = 0;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initBetValue(): TextField {
    const betValue = new TextField(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      300,
      100,
      betValueStyle as Partial<PIXI.ITextStyle>,
    );
    betValue.text.name = 'betValue';
    betValue.text.y = -45;
    betValue.text.x = 0;
    betValue.text.anchor.set(0.5, 0.5);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnDisabled),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    cancelBtn.name = 'cancelBtn';
    cancelBtn.y = 155;
    cancelBtn.x = -42;
    cancelBtn.anchor.set(1, 0.5);
    return cancelBtn;
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    setIsBuyFeaturePopupOpened(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
    eventManager.emit(EventTypes.CLOSE_BACKDROP_BG);
  }

  private initOkBtn(): SpriteButton {
    const confirmBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnDisabled),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleClickOk.bind(this),
      onTouchStart: this.handleClickOk.bind(this),
    });
    confirmBtn.name = 'confirmBtn';
    confirmBtn.y = 155;
    confirmBtn.x = 42;
    confirmBtn.anchor.set(0, 0.5);
    return confirmBtn;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.linesAmount;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1] > this.betSettings!.minBet) {
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnPressed);
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * 25);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1] < this.betSettings!.maxBet) {
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnPressed);
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * 25);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private updateBets(): void {
    this.totalCostTextAmount.text.text = this.getTotalCost();
    this.betValue.text.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
  }

  private getTotalCost = (): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetValue = (): number => {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (): number => {
    const bonuses = setSlotConfig().slotSettings.buyFeaturePackages;
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.packageId === FeatureTypes.BUY_FEATURE_FREE_SPINS)
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmountMultiplier;
  };

  private handleClickOk = (): void => {
    if (!this.isNoFunds) {
      AudioApi.play({ type: ISongs.SFX_UI_General });
      eventManager.emit(
        EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
        this.getTotalCost(),
        this.betSettings.bets[this.betAmount],
      );
      eventManager.emit(EventTypes.OPEN_BACKDROP_BG);
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount());
    if (this.isNoFunds) {
      this.okBtn.disable();
    } else {
      this.okBtn.enable();
    }

    if (bet === this.betSettings!.minBet) {
      this.minusBtn.interactive = false;
      this.minusBtn.alpha = 0.3;
    } else {
      this.minusBtn.interactive = true;
      this.minusBtn.alpha = 1;
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.interactive = false;
      this.plusBtn.alpha = 0.3;
    } else {
      this.plusBtn.interactive = true;
      this.plusBtn.alpha = 1;
    }
  };

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }
}

export default BuyFeaturePopup;
