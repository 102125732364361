import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../config';

class Backdrop extends PIXI.Container {
  backdrop: PIXI.Sprite;

  constructor(openOn: EventTypes, closeOn: EventTypes) {
    super();
    this.backdrop = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.backdrop));
    this.backdrop.name = 'backdrop';
    this.backdrop.visible = false;
    this.backdrop.alpha = 0.75;
    this.backdrop.interactive = true;
    this.addChild(this.backdrop);
    this.expandBackdropOverScreen();
    this.trackVisibility(openOn, closeOn);
  }

  public trackVisibility(openOn: EventTypes, closeOn: EventTypes): void {
    eventManager.on(openOn, () => {
      this.backdrop.visible = true;
    });
    eventManager.on(closeOn, () => {
      this.backdrop.visible = false;
    });
  }

  // Workaround to position backdrop over view in all landscapes
  public expandBackdropOverScreen(): void {
    this.backdrop.scale.set(4);
    this.x = -(this.backdrop.width / 2);
    this.y = -(this.backdrop.height / 2);
  }
}
export default Backdrop;
