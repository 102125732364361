import { Container } from 'pixi.js';

import { SlotId } from '../config';
import { IWinReward } from '../global';

export interface ISlotMachine {
  transparent: boolean;
  width: number;
  height: number;
}

export interface IPlayerSettingsMinMax {
  min: number;
  max: number;
}

export interface IPlayerSettings {
  betAmount: IPlayerSettingsMinMax;
  coinValue: {
    EUR: IPlayerSettingsMinMax;
    FUN: IPlayerSettingsMinMax;
  };
}

export interface IAutoplaySettings {
  conditions: {
    stopIfBalanceDecreasesBy: {
      enabled: boolean;
    };
    stopIfBalanceIncreasesBy: {
      enabled: boolean;
      multipliers: number[];
    };
    stopIfFeatureIsWon: {
      enabled: boolean;
    };
    stopIfSingleWinExceeds: {
      enabled: boolean;
      multipliers: number[];
    };
    stopOnAnyWin: {
      enabled: boolean;
    };
  };
  options: number[];
}

export interface IGameSettings {
  slotId: string;
  clientSlotSettings: {
    autoplaySettings: IAutoplaySettings;
    betCoinAmountSettings: number[];
    coinValueSettings: { code: string; variants: number[] }[];
    quickBetCoinAmountSettings: number[];
  };
  slotSettings: {
    cols: number;
    rows: number;
    startingPositions: number[];
    betLines: {
      min: number;
      max: number;
    };
    globalCoinAmountMultiplier: number;
    buyFeaturePackages: {
      coinAmountMultiplier: number;
      packageId: string;
      rounds: number;
    }[];
  };
}

export interface Features {
  id: string;
  enabled: boolean;
}

export interface LineSet {
  id: string;
  slotId: string;
  lines: number[];
  coinAmountMultiplier: number;
}

export interface IGameContainer {
  linesContainer: Container;
  winSlotsContainer: Container;
  mysteryRevealContainer: Container;
  reelsBackgroundContainer: Container;
  reelsContainer: Container;
  tintContainer: Container;
  miniPayTableContainer: Container;
  winLabelContainer: Container;
  winCountUpMessage: Container;
}

export type Combos = IconCombo[] | undefined;

export interface Icon {
  id: SlotId;
  type: IconType;
}

export interface IWinLine {
  lineNumber: number;
  attribution: number[];
  rewards: IWinReward[];
}

export enum IconType {
  EMPTY = 'EMPTY',
  REGULAR = 'REGULAR',
  SCATTER = 'SCATTER',
  WILD = 'WILD',
}

export enum PayoffType {
  LTR = 'LTR',
  RTL = 'RTL',
  ANY = 'ANY',
  ADJACENT = 'ADJACENT',
}
export enum RewardType {
  COINS = 'COINS',
  BONUS = 'BONUS',
}
export interface Reward {
  bonusId: string | null;
  count: number | null;
  multiplier: number | null;
  type: RewardType;
}
export interface IconCombo {
  pattern: string;
  multiplier: number;
}
export interface Combo {
  multiplier: number;
  pattern: string;
}
