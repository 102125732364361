import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { setBrokenGame } from '../../../gql';
import { ResourceTypes } from '../../../resources.d';
import { isFreeSpinMode } from '../../../utils';
import { ViewContainer } from '../../components/container';
import { ANTICIPATION_SLOTS_TINT, REELS_AMOUNT, REEL_WIDTH, SLOT_WIDTH, eventManager } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private lights: PIXI.Sprite[] = [];

  constructor() {
    super();
    this.x = -15;
    this.y = -10;
    this.initBackground();

    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStoped.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
  }

  initBackground(): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const container = new PIXI.Container();
      const texture = setBrokenGame()
        ? PIXI.Texture.from(ResourceTypes[`reelsLight${i + 1}Free` as ResourceTypes])
        : PIXI.Texture.from(ResourceTypes[`reelsLight${i + 1}` as ResourceTypes]);
      const light = new PIXI.Sprite(texture);
      this.lights.push(light);
      container.y = 0;
      container.x = i * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
      container.addChild(light);
      this.addChild(container);
    }
  }

  private onAnticipationStart(): void {
    this.lights.forEach((light) => {
      light.tint = ANTICIPATION_SLOTS_TINT;
    });
  }

  private onReelsStoped(): void {
    this.lights.forEach((light) => {
      light.tint = 0xffffff;
    });
  }

  onModeChange(settings: { mode: GameMode }) {
    this.lights.forEach((light, index) => {
      if (isFreeSpinMode(settings.mode)) {
        light.texture = PIXI.Texture.from(ResourceTypes[`reelsLight${index + 1}Free` as ResourceTypes]);
      } else {
        light.texture = PIXI.Texture.from(ResourceTypes[`reelsLight${index + 1}` as ResourceTypes]);
      }
    });
  }
}

export default ReelsBackgroundContainer;
