import { useMutation } from '@apollo/client';
import _ from 'lodash';
import React, { useEffect } from 'react';

import { EventTypes, FeatureTypes, IBonus } from '../../global.d';
import { buyFeatureGql, isStoppedGql, setCoinAmount, setCoinValue, setSlotConfig } from '../../gql';
import { eventManager } from '../../slotMachine/config';

const BuyFeature: React.FC = () => {
  const [buyBonus, { client }] = useMutation(buyFeatureGql, {
    onCompleted() {
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      // Triggers free spins
      eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
    },
  });

  useEffect(() => {
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, () => {
      const bonuses = setSlotConfig().slotSettings.buyFeaturePackages;
      const bonus = _.chain(bonuses)
        .filter((bonus) => bonus.packageId === FeatureTypes.BUY_FEATURE_FREE_SPINS)
        .get(0, {})
        .value() as IBonus;
      buyBonus({
        variables: {
          input: {
            slotId: setSlotConfig().slotId,
            packageId: bonus.packageId,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      });
    });
  }, []);

  return null;
};

export default BuyFeature;
