import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { setCurrency, setSlotConfig } from '../../gql/cache';
import { type ISlotHistory, type ISlotHistoryData } from '../../gql/d';
import { slotHistoryGql } from '../../gql/query';
import { normalizeCoins, showCurrency } from '../../utils';

import styles from './history.module.scss';
import LeftSvg from './leftSvg';
import RightSvg from './rightSvg';
import SkipLeftSvg from './skipLeftSvg';
import SkipRightSvg from './skipRightSvg';

const formatHistory = (historyData: ISlotHistoryData['bets']) => {
  const history = historyData.map((elem) => {
    const date = _.get(elem, 'createdAt', Date.now());
    const coins = ((_.get(elem, 'effectiveCoinAmount', 1) || 0) * (_.get(elem, 'coinValue', 1) || 100)) / 100;

    const bet = formatNumber({
      currency: setCurrency(),
      value: coins,
      showCurrency: showCurrency(setCurrency()),
    });
    const win = formatNumber({
      currency: setCurrency(),
      value: normalizeCoins(elem.betStorage.estimatedWinCoinAmount, _.get(elem, 'coinValue', 1)),
      showCurrency: showCurrency(setCurrency()),
    });

    return {
      date,
      bet,
      win,
      gameId: elem.id,
    };
  });

  return history;
};

const renderKey = (item: ISlotHistory, key: string) => {
  if (key === 'date') {
    return new Date(item[key as keyof ISlotHistory] as string).toLocaleString('en-US', { hour12: true });
  }

  if (key === 'bet') {
    return item[key as string];
  }

  return item[key as string];
};
const HistoryComponent: React.FC = () => {
  const { head, showBy } = config.historyTable;
  const { t } = useTranslation();

  const { data: historyData, refetch } = useQuery<
    {
      betHistory: ISlotHistoryData;
    },
    {
      input: {
        slotId: string;
        limit: number;
        before?: string;
        after?: string;
      };
    }
  >(slotHistoryGql, {
    variables: {
      input: { slotId: setSlotConfig().slotId, limit: showBy },
    },
    fetchPolicy: 'network-only',
  });

  const handleFetchMore = (direction = 'NEXT') => {
    const variables = {
      input: {
        slotId: setSlotConfig().slotId,
        limit: showBy,
        ...(direction === 'PREV' && { after: historyData?.betHistory.startCursor }),
        ...(direction === 'NEXT' && { before: historyData?.betHistory.endCursor }),
        ...(direction === 'LAST' && { after: new Date(1999, 1, 1, 1, 1, 1).toISOString() }),
      },
    };
    refetch({
      input: variables.input,
    });
  };
  if (!historyData?.betHistory.bets) {
    return (
      <div className={styles['history']}>
        <div className={styles['spinner']} />
      </div>
    );
  }

  const { bets } = historyData.betHistory;
  return (
    <div className={styles['history']}>
      <div className={styles['container']}>
        {formatHistory(bets).map((item) => (
          <div key={item.gameId} className={styles['history-item']}>
            {Object.keys(head).map((key) => (
              <div key={key} className={`${styles['history-item__col']} ${styles[key as string]}`}>
                {t(key)}
                <div className={styles['value']}>{renderKey(item, key)}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {historyData?.betHistory.count > showBy && (
        <div className={styles['pagination']}>
          <Button
            disabled={!historyData?.betHistory.hasPreviousPage}
            className={styles['pagination__btn'] as string}
            onClick={() => handleFetchMore('FIRST')}
          >
            <SkipLeftSvg />
          </Button>
          <Button
            disabled={!historyData?.betHistory.hasPreviousPage}
            className={styles['pagination__btn'] as string}
            onClick={() => handleFetchMore('PREV')}
          >
            <LeftSvg />
          </Button>
          <Button
            disabled={!historyData?.betHistory.hasNextPage}
            className={styles['pagination__btn'] as string}
            onClick={() => handleFetchMore('NEXT')}
          >
            <RightSvg />
          </Button>
          <Button
            disabled={!historyData?.betHistory.hasNextPage}
            className={styles['pagination__btn'] as string}
            onClick={() => handleFetchMore('LAST')}
          >
            <SkipRightSvg />
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(HistoryComponent);
