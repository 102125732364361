import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import { EventTypes, MessageBannerProps } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/container';
import { eventManager } from '../config';

import { buttonPosition } from './config';
import { btnStyle } from './textStyles';

interface IOptions {
  text?: string;
  position?: number;
  styles?: PIXI.TextStyle;
}

class MessageBanner extends ViewContainer {
  public banner: PIXI.Sprite;

  private title: TextField;

  private subtitle: TextField;

  private btn: TextField;

  private additionalText: TextField;

  private bindedHandleDestroy = this.handleDestroy.bind(this);

  constructor(props: MessageBannerProps) {
    super();
    this.visible = true;
    this.interactive = true;
    this.on('click', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.banner = this.initBanner();
    this.title = this.initText({
      text: props.title,
      position: props.titlePosition,
      styles: props.titleStyles as PIXI.TextStyle,
    });
    this.subtitle = this.initText({
      text: props.subtitle,
      position: props.subtitlePosition,
      styles: props.subtitleStyles as PIXI.TextStyle,
    });
    this.additionalText = this.initText({
      text: props.additionalText,
      position: props.additionalPosition,
      styles: props.additionalStyles as PIXI.TextStyle,
    });
    this.btn = this.initBtn(props.btnText);
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    this.init();
    if (props.onInitCallback) {
      props.onInitCallback();
    }
  }

  private init(): void {
    eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    eventManager.emit(EventTypes.OPEN_BACKDROP_BG);
    this.addChild(this.banner);
    this.addChild(this.title.getText());
    this.addChild(this.subtitle.getText());
    this.addChild(this.additionalText.getText());
    this.addChild(this.btn.getText());
  }

  private initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanner));
    banner.anchor.set(0.5);
    banner.x = 0;
    banner.y = 0;

    return banner;
  }

  private initText(options: IOptions): TextField {
    const text = new TextField(i18n.t<string>(options.text || ''), 1000, 100, options.styles);
    text.text.anchor.set(0.5, 0);
    text.text.y = options.position ? options.position : 0;
    text.text.x = 0;
    text.text.visible = !!options.text;

    return text;
  }

  private initBtn(btnText?: string): TextField {
    const btn = new TextField(i18n.t<string>(btnText || ''), 750, 100, btnStyle);
    btn.text.y = buttonPosition;
    btn.text.visible = !!btnText;
    btn.text.anchor.set(0.5, 0.5);

    return btn;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    this.destroy({ children: true, texture: false, baseTexture: false });
    eventManager.emit(EventTypes.CLOSE_BACKDROP_BG);
  }
}

export default MessageBanner;
