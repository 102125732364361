import { TextMetrics, TextStyle } from 'pixi.js';
import type React from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('info.mystery.title')}</h1>
      <div className={styles.figcontainer}>
        <div className={styles.fig}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.symMs1)} alt="mystery" />
          <p
            className={styles.caption}
            dangerouslySetInnerHTML={{
              __html: t('info.mystery.caption.baseGame', {
                interpolation: { escapeValue: false },
                style: 'color: rgb(255, 204, 72)',
              }),
            }}
          ></p>
        </div>
        <div className={styles.fig}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.symMs2)} alt="mystery-freespin" />
          <p
            className={styles.caption}
            dangerouslySetInnerHTML={{
              __html: t('info.mystery.caption.freeSpins', {
                interpolation: { escapeValue: false },
                style: 'color: rgb(255, 204, 72)',
              }),
            }}
          ></p>
        </div>
        <div className={`${styles['row']}`}>
          <div className={`${styles['col']}`}>
            <p
              className={`${styles['p']}`}
              dangerouslySetInnerHTML={{
                __html: t('info.mystery.text'),
              }}
            />
          </div>
        </div>
      </div>
      <h1 className={styles['subtitle']}>{t('info.featureFreeSpins.title')}</h1>
      <div className={`${styles['row']}`}>
        <div className={`${styles['col']}`}>
          <p
            className={`${styles['p']}`}
            dangerouslySetInnerHTML={{
              __html: t('info.featureFreeSpins.text'),
            }}
          />
        </div>
      </div>
    </section>
  );
};

const BuyAFeature: React.VFC = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);

  const calcFontSize = (fontSize: string | number, boxWidth: number, textWidth: number) => {
    if (boxWidth >= textWidth) {
      return Number(fontSize);
    }

    return Math.floor(Number(fontSize) * (boxWidth / textWidth));
  };

  useEffect(() => {
    if (ref.current) {
      const style = new TextStyle({
        fontSize: 28,
        fontWeight: '700',
      });
      const text1Width = TextMetrics.measureText(t('info.features.imgText'), style).width;
      const text2Width = TextMetrics.measureText(t('info.features.imgText2'), style).width;
      const boxWidth = ref.current.clientWidth - 15;

      if (text1Width > text2Width) {
        const fontSize = calcFontSize(style.fontSize, boxWidth, text1Width);

        ref.current.style.fontSize = `${fontSize}px`;
      } else {
        const fontSize = calcFontSize(style.fontSize, boxWidth, text2Width);

        ref.current.style.fontSize = `${fontSize}px`;
      }
    }
  }, [t]);

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('info.featureBuy.title')}</h1>
      <div className={styles['buy_feature']}>
        <div className={styles['buy_feature__img position']} ref={ref}>
          <img
            className={styles['buy_feature__img']}
            draggable="false"
            src={Resources.getSource(ResourceTypes.buyFeatureBtn)}
            alt="buy-a-feature"
          />
          <p className={styles['buy_feature__text']} dangerouslySetInnerHTML={{ __html: t('buyFeatureBtn') }} />
        </div>
      </div>
      <div className={`${styles['row']}`}>
        <div className={`${styles['col']}`}>
          <div className={`${styles['p']}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('info.featureBuy.text', {
                  featureCost: 80,
                }),
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('info.features.title')}</h1>
      <FreeSpinFeature />
      <BuyAFeature />
    </div>
  );
};

export default Features;
