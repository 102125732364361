import * as PIXI from 'pixi.js';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import { createZoomAnimation } from '../animations/helper';
import Tween from '../animations/tween';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/container';
import {
  BIG_WIN_TITLE,
  BIG_WIN_TITLE_SCALE,
  BIG_WIN_ZOOM_TITLE_DURATION,
  EPIC_WIN_TITLE,
  EPIC_WIN_TITLE_SCALE,
  EPIC_WIN_ZOOM_TITLE_DURATION,
  GREAT_WIN_TITLE,
  GREAT_WIN_TITLE_SCALE,
  GREAT_WIN_ZOOM_TITLE_DURATION,
  MEGA_WIN_TITLE,
  MEGA_WIN_TITLE_SCALE,
  MEGA_WIN_ZOOM_TITLE_DURATION,
  WinStages,
  bigWinStyles,
  epicWinStyles,
  eventManager,
  greatWinStyles,
  megaWinStyles,
} from '../config';

class WinLabelContainer extends ViewContainer {
  public bigWinLabel: TextField;

  public megaWinLabel: TextField;

  public greatWinLabel: TextField;

  public epicWinLabel: TextField;

  private bigWinAnimation: Animation | null = null;

  private megaWinAnimation: Animation | null = null;

  private greatWinAnimation: Animation | null = null;

  private epicWinAnimation: Animation | null = null;

  private bigWinFadeOutAnimation: Animation | null = null;

  private megaWinFadeOutAnimation: Animation | null = null;

  private greatWinFadeOutAnimation: Animation | null = null;

  private epicWinFadeOutAnimation: Animation | null = null;

  private winSprite: PIXI.Sprite;

  constructor() {
    super();
    this.zIndex = 1;
    this.winSprite = this.initWinSprite();
    this.addChild(this.winSprite);
    this.bigWinLabel = this.initBigWin();
    this.megaWinLabel = this.initMegaWin();
    this.greatWinLabel = this.initGreatWin();
    this.epicWinLabel = this.initEpicWin();

    eventManager.addListener(EventTypes.SET_BIG_WIN_VISIBILITY, this.setBigWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_MEGA_WIN_VISIBILITY, this.setMegaWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_GREAT_WIN_VISIBILITY, this.setGreatWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_EPIC_WIN_VISIBILITY, this.setEpicWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SKIP_ALL_WIN_ANIMATIONS, this.skipAllWinAnimations.bind(this));
    eventManager.addListener(EventTypes.HANDLE_START_FADE_ANIMATION, (stage: number) => this.startFade(stage));
    eventManager.addListener(EventTypes.HANDLE_SKIP_FADE_ANIMATION, () => this.skipFadeAnimation());
  }

  private initWinSprite(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanner));
    banner.anchor.set(0.5);
    banner.x = 0;
    banner.y = 0;
    banner.visible = false;

    return banner;
  }

  private initBigWin(): TextField {
    const bigWin = new TextField(BIG_WIN_TITLE, 800, 105, bigWinStyles);
    bigWin.text.anchor.set(0.5, 0.5);
    bigWin.text.position.set(0, -90);
    bigWin.text.visible = false;
    this.addChild(bigWin.getText());
    return bigWin;
  }

  private initMegaWin(): TextField {
    const megaWin = new TextField(MEGA_WIN_TITLE, 800, 105, megaWinStyles);
    megaWin.text.anchor.set(0.5, 0.5);
    megaWin.text.position.set(0, -90);
    megaWin.text.visible = false;
    this.addChild(megaWin.getText());
    return megaWin;
  }

  private initGreatWin(): TextField {
    const greatWin = new TextField(GREAT_WIN_TITLE, 800, 105, greatWinStyles);
    greatWin.text.anchor.set(0.5, 0.5);
    greatWin.text.position.set(0, -90);
    greatWin.text.visible = false;
    this.addChild(greatWin.getText());
    return greatWin;
  }

  private initEpicWin(): TextField {
    const epicWin = new TextField(EPIC_WIN_TITLE, 800, 105, epicWinStyles);
    epicWin.text.anchor.set(0.5, 0.5);
    epicWin.text.position.set(0, -90);
    epicWin.text.visible = false;
    this.addChild(epicWin.getText());
    return epicWin;
  }

  private setBigWinVisibility(visible: boolean) {
    this.winSprite.visible = visible;
    this.bigWinLabel.text.visible = visible;
    const initScale = this.bigWinLabel.text.scale.x;
    if (visible) {
      this.bigWinAnimation?.skip();
      this.bigWinAnimation = createZoomAnimation(
        this.bigWinLabel.text,
        BIG_WIN_TITLE_SCALE * initScale,
        BIG_WIN_ZOOM_TITLE_DURATION,
        false,
        initScale,
      );
      this.bigWinAnimation.addOnSkip(() => {
        this.bigWinLabel.text.scale.set(initScale);
      });
      this.bigWinAnimation.start();
    } else {
      this.bigWinAnimation?.skip();
      this.bigWinAnimation = null;
    }
  }

  private setMegaWinVisibility(visible: boolean) {
    this.winSprite.visible = visible;
    this.megaWinLabel.text.visible = visible;
    const initScale = this.megaWinLabel.text.scale.x;
    if (visible) {
      this.megaWinAnimation?.skip();
      this.megaWinAnimation = createZoomAnimation(
        this.megaWinLabel.text,
        MEGA_WIN_TITLE_SCALE * initScale,
        MEGA_WIN_ZOOM_TITLE_DURATION,
        false,
        initScale,
      );
      this.megaWinAnimation.addOnSkip(() => {
        this.megaWinLabel.text.scale.set(initScale);
      });
      this.megaWinAnimation.start();
    } else {
      this.megaWinAnimation?.skip();
      this.megaWinAnimation = null;
    }
  }

  private setGreatWinVisibility(visible: boolean) {
    this.winSprite.visible = visible;
    this.greatWinLabel.text.visible = visible;
    const initScale = this.greatWinLabel.text.scale.x;
    if (visible) {
      this.greatWinAnimation?.skip();
      this.greatWinAnimation = createZoomAnimation(
        this.greatWinLabel.text,
        GREAT_WIN_TITLE_SCALE * initScale,
        GREAT_WIN_ZOOM_TITLE_DURATION,
        false,
        initScale,
      );
      this.greatWinAnimation.addOnSkip(() => {
        this.greatWinLabel.text.scale.set(initScale);
      });
      this.greatWinAnimation.addOnSkip(() => {
        this.greatWinLabel.text.scale.set(initScale);
      });
      this.greatWinAnimation.start();
    } else {
      this.greatWinAnimation?.skip();
      this.greatWinAnimation = null;
    }
  }

  private setEpicWinVisibility(visible: boolean) {
    this.winSprite.visible = visible;
    this.epicWinLabel.text.visible = visible;
    const initScale = this.epicWinLabel.text.scale.x;
    if (visible) {
      this.epicWinAnimation?.skip();
      this.epicWinAnimation = createZoomAnimation(
        this.epicWinLabel.text,
        EPIC_WIN_TITLE_SCALE * initScale,
        EPIC_WIN_ZOOM_TITLE_DURATION,
        false,
        initScale,
      );
      this.epicWinAnimation.addOnSkip(() => {
        this.epicWinLabel.text.scale.set(initScale);
      });
      this.epicWinAnimation.start();
    } else {
      this.epicWinAnimation?.skip();
      this.epicWinAnimation = null;
    }
  }

  private skipAllWinAnimations() {
    this.winSprite.visible = false;
    this.setBigWinVisibility(false);
    this.setMegaWinVisibility(false);
    this.setGreatWinVisibility(false);
    this.setEpicWinVisibility(false);
  }

  private skipFadeAnimation() {
    this.bigWinFadeOutAnimation?.skip();
    this.megaWinFadeOutAnimation?.skip();
    this.greatWinFadeOutAnimation?.skip();
    this.epicWinFadeOutAnimation?.skip();
  }

  private startFade(stage: WinStages) {
    if (stage === WinStages.BigWin) {
      this.bigWinFadeOutAnimation = this.createFadeAnimation(this.bigWinLabel.text);
      const onEnd = () => {
        this.bigWinLabel.text.visible = false;
        this.bigWinLabel.text.alpha = 1;
        this.bigWinFadeOutAnimation = null;
      };
      this.bigWinFadeOutAnimation.addOnSkip(onEnd);
      this.bigWinFadeOutAnimation.addOnComplete(onEnd);
      this.bigWinFadeOutAnimation.start();
    }

    if (stage === WinStages.MegaWin) {
      this.megaWinFadeOutAnimation = this.createFadeAnimation(this.megaWinLabel.text);
      const onEnd = () => {
        this.megaWinLabel.text.visible = false;
        this.megaWinLabel.text.alpha = 1;
        this.megaWinFadeOutAnimation = null;
      };
      this.megaWinFadeOutAnimation.addOnSkip(onEnd);
      this.megaWinFadeOutAnimation.addOnComplete(onEnd);
      this.megaWinFadeOutAnimation.start();
    }

    if (stage === WinStages.GreatWin) {
      this.greatWinFadeOutAnimation = this.createFadeAnimation(this.greatWinLabel.text);
      const onEnd = () => {
        this.greatWinLabel.text.visible = false;
        this.greatWinLabel.text.alpha = 1;
        this.greatWinFadeOutAnimation = null;
      };
      this.greatWinFadeOutAnimation.addOnSkip(onEnd);
      this.greatWinFadeOutAnimation.addOnComplete(onEnd);
      this.greatWinFadeOutAnimation.start();
    }
    if (stage === WinStages.EpicWin) {
      this.epicWinFadeOutAnimation = this.createFadeAnimation(this.epicWinLabel.text);
      const onEnd = () => {
        this.epicWinLabel.text.visible = false;
        this.epicWinLabel.text.alpha = 1;
        this.epicWinFadeOutAnimation = null;
      };
      this.epicWinFadeOutAnimation.addOnSkip(onEnd);
      this.epicWinFadeOutAnimation.addOnComplete(onEnd);
      this.epicWinFadeOutAnimation.start();
    }
  }

  private createFadeAnimation(target: PIXI.Text) {
    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: target,
      property: TweenProperties.ALPHA,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      duration: mappedAudioSprites[ISongs.BigWin_End].duration,
    });
    fadeOutAnimation.addOnComplete(() => {
      target.visible = false;
      target.alpha = 1;
    });
    return fadeOutAnimation;
  }
}

export default WinLabelContainer;
