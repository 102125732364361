import type React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const AutoPlay: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['autoplay']}>
      <h1 className={styles['title']}>{t('info.autoPlay.title')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          <p className={styles['p']} dangerouslySetInnerHTML={{ __html: t('info.autoPlay.text') }} />
        </div>
      </div>
    </div>
  );
};

export default AutoPlay;
