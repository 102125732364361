import type React from 'react';

const TurbospinOffSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M101.775 65.1315C103.033 63.5602 101.914 61.2319 99.9013 61.2319H84.3633C82.5791 61.2319 81.4188 59.3544 82.2166 57.7586L92.3113 37.5693C93.1092 35.9735 91.9488 34.0959 90.1647 34.0959H57.938C56.8367 34.0959 55.8767 34.8456 55.6097 35.9141L45.9612 74.5221C45.5827 76.0368 46.7283 77.504 48.2896 77.504H62.6432C64.0634 77.504 65.173 78.7304 65.0313 80.1435L62.298 107.395C62.0595 109.774 65.066 111.001 66.5599 109.134L101.775 65.1315Z"
        fill="white"
        className="svg-color"
      />
    </svg>
  );
};

export default TurbospinOffSvg;
