import * as PIXI from 'pixi.js';

import { MAPPED_BLURRED_SYMBOLS, MAPPED_SYMBOLS, SlotId } from '../../config';
import { getFromMappedSymbol } from '../../utils';
import { SLOT_HEIGHT, SLOT_WIDTH } from '../config';

class Slot extends PIXI.Sprite {
  private id: number;

  public slotId: SlotId;

  public textureSlotId: SlotId;

  constructor(id: number, slotId: SlotId) {
    super(PIXI.Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId)));
    this.id = id;
    this.slotId = slotId;
    this.textureSlotId = slotId;
    this.width = SLOT_WIDTH;
    this.height = SLOT_HEIGHT;
    this.y = SLOT_HEIGHT * id;
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = PIXI.Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    this.textureSlotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      this.texture = PIXI.Texture.from(getFromMappedSymbol(MAPPED_BLURRED_SYMBOLS, this.textureSlotId));
    } else {
      this.texture = PIXI.Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, this.textureSlotId));
    }
  }

  public onSlotStopped(): void {
    // todo add sound/animation on slot stop
  }
}

export default Slot;
