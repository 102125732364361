import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { IBet } from './gql/d';

export interface IBalance {
  amount: number;
  currency: string;
}

export enum RewardType {
  COINS = 'COINS',
  BONUS = 'BONUS',
}

export interface IWinReward {
  type: RewardType;
  value: number;
  multiplier: number;
  entityId?: string | null;
}

export interface IOutcome {
  id: string;
  rewards: IWinReward[];
  predicament: {
    reelPositions: number[];
    mysterySymbol: ISlotId;
  };
  stateSnapshot: {
    fieldState: ISlotId[];
    hasRewardedFreeSpins: boolean;
    winCombinations: {
      attribution: number[];
      lineNumber: number;
      rewards: {
        entityId: string;
        multiplier: number;
        value: number;
      }[];
    }[];
  };
}

export interface IAuthInput {
  clientId: string;
  connectToken: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface ISettledBet {
  bet: IBet;
  balance: {
    settled: {
      amount: number;
      currency: string;
    };
    placed: {
      amount: number;
      currency: string;
    };
  };
  winCoinAmount: number;
}
export type IReelSet = {
  layout: SlotId[][];
  type: GameMode;
  additionalReelSets: SlotId[][];
};

export enum GameMode {
  BASE_GAME = 'BASE_GAME',
  FREE_SPINS = 'FREE_SPINS',
  BUY_FEATURE = 'BUY_FEATURE',
}

export interface IWager {
  bet: IBet | null;
  coinAmount: number;
  coinValue: number;
  createdAt: string;
  id: string;
  order: number;
  playerId: string;
  slotId: string;
  state: string;
  wagerSettings: {
    gameMode: GameMode;
    packageId: string;
    rounds: number;
    originalReelPositions: number[];
    originalGameMode: GameMode;
    mysterySymbol?: SlotId;
  };
  wagerStorage: {
    roundsPlayed: number;
    totalWinCoinAmount: number;
    previousTotalWinCoinAmount: number;
  };
}

export interface IBonusNew {
  packageId: string;
  gameMode: GameMode;
  rounds: number;
  roundsPlayed: number;
  state: BonusStatus;
  coinAmount: number;
  coinValue: number;
  originalReelPositions: number[];
  isBuyFeature: boolean;
  mysterySymbol?: SlotId;
}

export interface IBonus {
  packageId: string;
  coinAmountMultiplier: number;
  rounds: number;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export type MessageBannerProps = {
  title?: string;
  titlePosition?: number;
  titleStyles?: PIXI.TextStyle;
  subtitle?: string;
  subtitlePosition?: number;
  subtitleStyles?: PIXI.TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: PIXI.TextStyle;
  preventDefaultDestroy?: boolean;
  callback?: () => void;
  onInitCallback?: () => void;
};

export enum EventTypes {
  OPEN_BACKDROP_BG = 'OPEN_BACKDROP_BG',
  CLOSE_BACKDROP_BG = 'CLOSE_BACKDROP_BG',
  ANTICIPATION_STARTS = 'anticipationStarts',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_WIN_LINES = 'hideWinLines',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  HANDLE_IS_ACTIVE_FREE_SPINS_GAME = 'handleIsActiveFreeSpinsGame',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REPLACE_MYSTERY_SLOTS = 'replaceMysterySlots',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  OPEN_BUY_FEATURE_CONFIRM_POPUP_BG = 'openBuyFeatureConfirmPopupBg',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  SOUND_INITIALIZED = 'soundInitialized',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
  FORCE_CLOSE_BUYFEATURE = 'forceStopBuyFeature',
  SET_LAST_BET_RESULT_AFTER_FREE_SPINS = 'setLastBetResultAfterFreeSpins',
}

declare global {
  interface Window {
    __ENV__: {
      APP_VERSION: string;
      APP_NAME: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      REACT_APP_TRANSLATE_URL: string;
      ENV: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    Howler: {
      _spriteBaseUrl: string;
    };
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export enum BonusStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum FeatureTypes {
  BUY_FEATURE_FREE_SPINS = 'BUY_FEATURE_FREE_SPINS',
  FREE_SPIN = 'FREE_SPIN',
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
}
