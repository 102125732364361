import { TextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const buyFeatureTitleStyle = new TextStyle({
  fontSize: 46,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '900',
  fill: '#ffffff',
  stroke: '#460211',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  align: 'center',
});

export const totalCostTextStyle = new TextStyle({
  fontSize: 36,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  fill: '#FFFF54',
  stroke: '#460211',
  strokeThickness: 3,
  lineJoin: 'round',
});

export const totalCostTextAmountStyle = new TextStyle({
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  lineHeight: 50,
  fill: '#ffffff',
  stroke: '#460211',
  strokeThickness: 3,
  lineJoin: 'round',
});

export const amountTextStyle = new TextStyle({
  fontSize: 36,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  lineHeight: 50,
  fill: '#FFFF54',
  stroke: '#460211',
  strokeThickness: 3,
  lineJoin: 'round',
  wordWrap: true,
  wordWrapWidth: 240,
  breakWords: true,
});

export const betValueStyle = new TextStyle({
  fontSize: 36,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  lineHeight: 50,
  fill: '#FFFFFF',
  lineJoin: 'round',
  trim: true,
});
