import type { TPlay } from '@phoenix7dev/audio-api/dist/d';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../config';
import { BonusStatus, RewardType, GameMode, IBonusNew, IReelSet, IOutcome, IWager } from '../global.d';
import { IConfig } from '../gql/d';
import { Icon } from '../slotMachine/d';
import { isMobile } from 'pixi.js';
import { setIsDuringBigWinLoop, setIsMessageBannerOpened } from '../gql/cache';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
    (...partTwo: Helper.RestArguments): unknown => {
      const args: Helper.RestArguments = [...partOne, ...partTwo];
      if (args.length) {
        return fn(...args);
      }
      return fn();
    };

export const getUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    if (config[name] === undefined) {
      Object.assign(config, { [name]: value });
      localStorage.setItem('config', JSON.stringify(config));
    }
    return config[name] as boolean;
  }
  localStorage.setItem('config', JSON.stringify({ [name]: value }));
  return value;
};

export const setUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    Object.assign(config, { [name]: value });
    localStorage.setItem('config', JSON.stringify(config));
  }
  return value;
};

export const isFreeSpinMode = (mode: GameMode): boolean => mode === GameMode.FREE_SPINS;
export const isBaseGameMode = (mode: GameMode): boolean => mode === GameMode.BASE_GAME;
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return (
    ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || 25)) / 100
  );
};

export const getIconById = (icons: Icon[], id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  throw error;
};
export const getBonusFromRewards = (wager: IWager,outcome: IOutcome): IBonusNew => {
  
  const bonus = outcome.rewards.find( bonus => bonus.type === RewardType.BONUS)
  
  if(bonus) {
    return {
      packageId: bonus?.entityId as GameMode,
      gameMode: bonus?.entityId as GameMode,
      rounds: bonus?.value,
      roundsPlayed: 0,
      state: BonusStatus.OPEN,
      coinAmount: wager.coinAmount,
      coinValue: wager.coinValue,
      originalReelPositions: wager.wagerSettings.originalReelPositions,
      isBuyFeature: wager.wagerSettings.gameMode === GameMode.BUY_FEATURE ||  wager.wagerSettings.originalGameMode === GameMode.BUY_FEATURE,
    }
  }

  return {
    packageId: wager.wagerSettings.packageId,
    gameMode: wager.wagerSettings.gameMode,
    rounds: wager.wagerSettings.rounds,
    roundsPlayed: wager.wagerStorage.roundsPlayed,
    state: BonusStatus.OPEN,
    coinAmount: wager.coinAmount,
    coinValue: wager.coinValue,
    originalReelPositions: wager.wagerSettings.originalReelPositions,
    isBuyFeature: wager.wagerSettings.gameMode === GameMode.BUY_FEATURE ||  wager.wagerSettings.originalGameMode === GameMode.BUY_FEATURE,
  }
}


export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: IReelSet;
  icons: Icon[];
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom =
        random - 1 < 0 ? reelSet.layout[index].length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index][prevRandom]);
    }),
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index][random]);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom =
        random + 1 >= reelSet.layout[index].length ? 0 : random + 1;
      return getIconById(icons, reelSet.layout[index][nextRandom]);
    }),
  ];
  return spinResult;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  localStorage.setItem('positions', btoa(positions));
};

export const calcPercentage = (
  initialValue: number,
  percent: number,
): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isBuyFeaturePopupOpened,
  isAutoPlay,
  brokenBuyFeature,
  bonusCurrentRound,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isBuyFeaturePopupOpened: boolean;
  isAutoPlay?: boolean;
  brokenBuyFeature?: boolean;
  bonusCurrentRound: number;
}): boolean => {

  if (
    (gameMode === GameMode.BASE_GAME || gameMode === GameMode.BUY_FEATURE) &&
    isFreeSpinsWin
  ) {
    return false;
  }

  if (
    !setIsMessageBannerOpened().opened && 
    isFreeSpinMode(gameMode) &&
    ((bonusCurrentRound <= 1 && isSpinInProgress) || !isSlotBusy)
  ) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  if (isAutoPlay) {
    return false;
  }

  if (brokenBuyFeature) {
    return false;
  }

  return true;
};

export const handleChangeRestriction = (mode: GameMode): void => {
  AudioApi.unSuspend();
  AudioApi.processRestriction({
    restricted: false,
  });
  const list: TPlay[] = [];
  if (setIsDuringBigWinLoop()) {
    list.push({ type: ISongs.BigWin_Loop });
  }
  switch (mode) {
    case GameMode.BASE_GAME:
      list.push({ type: ISongs.BaseGameBGM_Base}, {type: ISongs.BaseGameBGM_Melo, volume: 0 });
      break;
    case GameMode.FREE_SPINS:
      list.push({ type: ISongs.FreeSpinBGM_Loop, stopImmediately: [ISongs.BaseGameBGM_Base, ISongs.BaseGameBGM_Melo] });
      break;
    default:
      list.push({ type: ISongs.BaseGameBGM_Base}, {type: ISongs.BaseGameBGM_Melo, volume: 0 });
      break;
  }
  AudioApi.playlist({ list });
};

export const isTabletPortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth >= 768 && _deviceWidth < 1000;
};
export const isTabletLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth >= 950 && _deviceHeight < 1200;
};
export const isMobilePortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth < 768;
};
export const isMobileLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth < 950;
};
