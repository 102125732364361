import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode } from '../../utils';
import { ViewContainer } from '../components/container';
import { eventManager } from '../config';

class Background extends ViewContainer {
  private baseBg = PIXI.Texture.from(ResourceTypes.slotBg);

  private bonusBg = PIXI.Texture.from(ResourceTypes.slotFreeSpinBg);

  private bgSprite = new PIXI.Sprite();

  constructor() {
    super();

    this.bgSprite.texture = setBrokenGame() ? this.bonusBg : this.baseBg;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeModeBackground.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeModeBackground.bind(this));
  }

  private changeModeBackground(settings: { mode: GameMode }) {
    if (isFreeSpinMode(settings.mode)) this.bgSprite.texture = this.bonusBg;
    if (settings.mode === GameMode.BASE_GAME) this.bgSprite.texture = this.baseBg;
  }

  resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;
