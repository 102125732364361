import { useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { setCurrency, setSlotConfig } from '../../gql';
import { getBetsSetting } from '../../utils/fromFragment';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation();
  const currency = useReactiveVar(setCurrency);

  const betSettings = getBetsSetting();
  const minBet = formatNumber({ currency, value: betSettings.minBetCount });
  const maxBet = formatNumber({ currency, value: betSettings.maxBetCount });
  const rtpBuyFeature = '96.66%';
  const rtp = setSlotConfig() ? '96.53%' : rtpBuyFeature;

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('info.gameRules.title')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']} dangerouslySetInnerHTML={{ __html: t('info.gameRules.text') }}></div>
      </div>
      <div className={styles['row']}>
        <div
          className={`${styles['col']} ${styles['center']}`}
          dangerouslySetInnerHTML={{
            __html: t('info.gameRules.bets', {
              minBet,
              maxBet,
              rtp,
              rtpBuyFeature,
              version: window.__ENV__.APP_VERSION,
            }),
          }}
        ></div>
      </div>
    </div>
  );
};

export default GameRules;
