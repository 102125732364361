import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';
import { Colors, Variables } from '../../config/variables';

export * from './textStyles';
export const eventManager = new PIXI.utils.EventEmitter();

window.eventManager = eventManager; // Trick to proxy eventManager to cheats file

export enum SlotMachineState {
  IDLE,
  SPIN,
  STOP,
  MYSTERY,
  JINGLE,
  WINNING,
  RETRIGGER,
}
// APPLICATION SETTINGS
export const APPLICATION_FPS = 60;

// WIN SETTINGS
export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.03;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.3;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.4;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.5;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.6;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.6;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;
export const MYSTERY_SPECIAL_REVEAL_ANIMATION_COEFFICIENT = 0.5;
export const SPRITE_ANIMATION_FPS = 30;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 5;
export const SLOTS_PER_REEL_AMOUNT = 3;
export const REEL_WIDTH = 230;
export const SLOT_WIDTH = 220;
export const SLOT_HEIGHT = 220;
export const GAME_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const GAME_CONTAINER_TITLE_LOGO_Z_INDEX = 100;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;

export const MINIMUM_SPIN_SLOTS_AMOUNT = 140;
export const GAME_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [SlotId.SC];
export const ANTICIPATION_SYMBOLS_AMOUNT = [2, 2];
export const ANTICIPATION_DURATION = 3000;
export const ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT = 15;
export const ANTICIPATION_SLOTS_TINT = 0xaaaaaa;

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 300;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}
// NUMBER FORMAT
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;
// SPIN ANIMATION SETTINGS
export const INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP = 25;
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_REEL_MYSTERY_DURATION = 7_000;
export const BASE_REEL_ROLLING_DURATION = 1250;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 13_000;
export const FAKE_ROLLING_SLOTS = 400;
export const TURBO_REEL_STARTING_DURATION = 250;
export const TURBO_REEL_ROLLING_DURATION = 500;
export const TURBO_REEL_ENDING_DURATION = 250;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = 3;
export const REEL_MYSTERY_SLOTS_AMOUNT = 175;
export const REEL_ENDING_SLOTS_AMOUNT = 3;
export const BASE_REEL_STARTING_FORMULA = (t: number): number => 3 * t * t * (1.4 * t - 0.4);
export const BASE_REEL_ROLLING_FORMULA = (t: number): number => -0.592691 + 1.77055 * t;
export const BASE_REEL_ENDING_FORMULA = (t: number): number => (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;
export const ANTICIPATION_REEL_ROLLING_FORMULA = (t: number): number => -0.6884 + 2.19771 * t;
export const ANTICIPATION_REEL_ENDING_FORMULA = (t: number): number => 0.5 + 0.5 * t;
export const REEL_MYSTERY_FORMULA = (n: number): number => {
  const offset = BASE_REEL_STARTING_DURATION / BASE_REEL_MYSTERY_DURATION;
  return n < offset ? 50 * n * n * (n - offset) : n - offset;
};

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;
export const TINT_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.2;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = SLOT_WIDTH;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;

// FREE SPINS
export const FREE_SPINS_FADE_DURATION = 4000;
export const FREE_SPINS_TIME_OUT_BANNER = 1000;

const winCounterStyle = new PIXI.TextStyle({
  align: 'center',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 60,
  fontWeight: '700',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#fff',
  strokeThickness: 22,
  whiteSpace: 'normal',
});

const winCounterStyle2 = new PIXI.TextStyle({
  align: 'center',
  fill: ['#fff27c', '#ffe388', '#ff9900', '#ffee54'],
  fillGradientStops: [0, 0.45, 0.55, 0.75],
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 60,
  fontWeight: '700',
  lineJoin: 'round',
  miterLimit: 0,
  strokeThickness: 14,
  whiteSpace: 'normal',
});

export const winValueStyles = new PIXI.TextStyle(winCounterStyle);
export const winValueStyles2 = new PIXI.TextStyle(winCounterStyle2);

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

// LINES SETTINGS
type Position = {
  [key: number]: {
    x: number;
    y: number;
  };
};
export const POSITION: Position = {
  0: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  1: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  2: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  3: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  4: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  5: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  6: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  7: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  8: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  9: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  10: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  11: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  12: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  13: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  14: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
};

export const soundToastStyles = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 40,
    fill: '#ffffff',
    stroke: 'black',
    strokeThickness: 5,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};
