import 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes, FreeSpinsTitleProps, GameMode, MessageBannerProps } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode, isMobileLandscape, isMobilePortrait, isTabletLandscape, isTabletPortrait } from '../../utils';
import { ViewContainer } from '../components/container';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import { IGameContainer } from '../d';
import FreeSpinsTitle from '../freeSpinsTitle/freeSpinsTitle';
import MessageBanner from '../messageBanner/messageBanner';

import GameTitle from './gameTitle';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.PIXI = PIXI;

class GameView extends ViewContainer {
  private mainContainer: PIXI.Sprite;

  public linesContainer: PIXI.Container;

  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public mysteryRevealContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public frame: PIXI.Sprite;

  public freeSpinsTitle: FreeSpinsTitle | undefined;

  public maskArea: PIXI.Graphics;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.mainContainer = new PIXI.Sprite(PIXI.Texture.EMPTY);
    this.mainContainer.name = 'mainContainer';
    this.mainContainer.anchor.set(0.5);
    this.addChild(this.mainContainer);
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.name = 'slotsContainer';
    this.slotsContainer.x = -SLOTS_CONTAINER_WIDTH / 2;
    this.slotsContainer.y = -SLOTS_CONTAINER_HEIGHT / 2;
    this.slotsContainer.interactive = true;
    this.maskArea = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH + 50, SLOTS_CONTAINER_HEIGHT + 20)
      .endFill();
    this.maskArea.x = -25;
    this.maskArea.y = -15;
    this.gameTitle = new GameTitle();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.x = this.slotsContainer.x;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.mysteryRevealContainer = props.mysteryRevealContainer;
    this.mysteryRevealContainer.x = this.slotsContainer.x;
    this.mysteryRevealContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.linesContainer = props.linesContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.linesContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.frame = this.initReelsFrame();
    this.mainContainer.addChild(this.slotsContainer);
    this.mainContainer.addChild(this.frame);
    this.mainContainer.addChild(this.miniPayTableContainer);
    this.mainContainer.addChild(this.gameTitle);
    this.mainContainer.addChild(this.winSlotsContainer);
    this.mainContainer.addChild(this.mysteryRevealContainer);
    this.mainContainer.addChild(this.winLabelContainer);
    this.mainContainer.addChild(this.winCountUpMessage);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPINS_TITLE, this.createFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.REMOVE_FREE_SPINS_TITLE, this.removeFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
  }

  private createFreeSpinsMessage(props: MessageBannerProps): void {
    this.addChild(new MessageBanner(props));
  }

  private removeFreeSpinsTitle(_props: FreeSpinsTitleProps): void {
    if (this.freeSpinsTitle) this.removeChild(this.freeSpinsTitle);
  }

  private createFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    this.freeSpinsTitle = new FreeSpinsTitle(props);
    this.addChildAt(this.freeSpinsTitle, 2);
  }

  onModeChange(settings: { mode: GameMode }): void {
    if (isFreeSpinMode(settings.mode)) {
      this.gameTitle.visible = false;
    } else {
      this.gameTitle.visible = true;
    }
  }

  private initReelsFrame() {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frame));
    frame.width = 1210;
    frame.height = 760;
    frame.y = 0;
    frame.x = 0;
    frame.anchor.set(0.5);
    frame.name = 'frameStatic';
    return frame;
  }

  private resizeGameContainer(width: number, height: number, deviceWidth: number, deviceHeight: number): void {
    this.scale.set(width / 1550);
    this.y = 0;

    if (isTabletLandscape(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1750);
    }
    if (isTabletPortrait(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1510);
      this.y = -height * 0.15;
    }
    if (isMobilePortrait(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1350);
      this.y = -height * 0.1;
    }
    if (isMobileLandscape(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1510);
    }
    this.x = width / 2;
  }
}

export default GameView;
