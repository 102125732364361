export const addMysteryTimeline: number[][] = [
  [
    433,
    766,
    1432,
    1765,
    2098,
    3430,
    3763,
    4096,
    4762,
    5095,
    5428,
    6094,
    6427,
    6760,
  ],
  [
    816,
    1149,
    1815,
    2148,
    2481,
    3147,
    3480,
    3813,
    4479,
    4812,
    5145,
    5811,
    6144,
    6477,
  ],
  [0, 333, 666, 1332, 1665, 1998, 2664, 2997, 3330, 4995, 5328, 5661],
  [1565, 1898, 2231],
  [2664, 2997, 3330],
];
