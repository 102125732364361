import AudioApi from '@phoenix7dev/audio-api';
import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import type { Application } from 'pixi.js';

import { ISongs, mappedAudioSprites, SlotId } from '../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  ISettledBet,
} from '../global.d';
import {
  client,
  isStoppedGql,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsErrorMessage,
  setIsFreeSpinsWin,
  setIsMessageBannerOpened,
  setIsRevokeThrowingError,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setPrevReelsPosition,
  setProgress,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../gql';
import {
  getBonusFromRewards,
  getSpinResult,
  isFreeSpinMode,
  normalizeCoins,
  showCurrency,
} from '../utils';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './controlButtons/autoplayBtn';
import MenuBtn from './controlButtons/menuBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import SpinBtn from './controlButtons/spinBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  eventManager,
  FREE_SPINS_TIME_OUT_BANNER,
  REELS_AMOUNT,
  SlotMachineState,
} from './config';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import LinesContainer from './lines/linesContainer';
import {
  additionalPosition,
  subtitlePosition,
  titlePosition,
  winSubtitlePosition,
  winTitlePosition,
} from './messageBanner/config';
import {
  additionalStyle,
  subtitleStyle,
  titleStyle,
  winSubtitleStyle,
  winTitleStyle,
} from './messageBanner/textStyles';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import Slot from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import MysteryRevealContainer from './winAnimations/mysteryRevealContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';
import { ISlotConfig } from '../gql/d';
import RetriggerMessage from './retrigger/retriggerMessage';
import { formatNumber } from '@phoenix7dev/utils-fe';

class SlotMachine {
  private readonly application: PIXI.Application;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: Application,
    gameSettings: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      application,
      gameSettings,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public gameView: GameView;

  public reelsContainer: ReelsContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public mysteryRevealContainer: MysteryRevealContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public infoBtn: InfoBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  private constructor(
    application: Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initEventListeners();
    this.application.stage.sortableChildren = true;
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    let { startingPositions } = slotConfig.slotSettings;
    let reelSet;
    if (setBrokenBuyFeature()) {
      reelSet = setUserLastBetResult().id
        ? slotConfig.reels.find(
          (reelSet) => {
            return reelSet.type === GameMode.BUY_FEATURE
          }
        )!
        : slotConfig.reels[0];
      setReelSetId(reelSet.type);
      setPrevReelsPosition(startingPositions.slice(0, 5));
      this.reelsContainer = new ReelsContainer(reelSet.layout, startingPositions);
    } else {
      const hasLastBet = setUserLastBetResult().id;
      startingPositions = hasLastBet
        ? setUserLastBetResult().outcomes[0].predicament.reelPositions
        : startingPositions;
      if (setCurrentBonus().state === BonusStatus.OPEN && setCurrentBonus().roundsPlayed === 0) {
        startingPositions = [0, 0, 0, 0, 0]
      }
      setPrevReelsPosition(startingPositions);
      let gameMode = setUserLastBetResult().wager.wagerSettings.gameMode;
      if ((hasLastBet && setUserLastBetResult().outcomes[0]!.stateSnapshot.hasRewardedFreeSpins) || gameMode === GameMode.BUY_FEATURE) {
        gameMode = GameMode.FREE_SPINS;
      }
      reelSet = hasLastBet
        ? slotConfig.reels.find(
          (reelSet) => {
            return GameMode[reelSet.type] === gameMode;
          }
        )!
        : slotConfig.reels[0];
      setReelSetId(reelSet.type);

      this.reelsContainer = new ReelsContainer(reelSet.layout, startingPositions);
      if (setUserLastBetResult().id) {
        eventManager.emit(
          EventTypes.REPLACE_MYSTERY_SLOTS,
          setUserLastBetResult().outcomes[0].predicament.mysterySymbol,
        );
      }

    }
    this.mysteryRevealContainer = new MysteryRevealContainer();
    this.miniPayTableContainer = new MiniPayTableContainer(
      slotConfig.icons,
      this.getSlotById.bind(this),
    );
    this.miniPayTableContainer.setSpinResult(
      getSpinResult({
        reelPositions: startingPositions.slice(0, 5),
        reelSet,
        icons: slotConfig.icons,
      }),
      setUserLastBetResult().id ? setUserLastBetResult().outcomes[0].predicament.mysterySymbol : reelSet.additionalReelSets[0][startingPositions[5] || 0],
    );
    this.gameView = this.initGameView(slotConfig);
    this.menuBtn = new MenuBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();
    this.initPixiLayers();
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.infoBtn);
    this.application.stage.addChild(this.autoplayBtn);
    if (setBrokenGame()) {
      eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
        text: i18n.t<string>('freeSpinsTitle'),
        spins: setCurrentBonus().rounds,
        currentSpin: setCurrentBonus().roundsPlayed,
      });
    }
  }

  private async onBrokenGame(): Promise<void> {
    const gameMode = setCurrentBonus().gameMode
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().gameMode);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);
    if (setProgress().wasLoaded) {
      this.setState(SlotMachineState.IDLE);
    } else {
      eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        this.setState(SlotMachineState.IDLE);
      });
    }
    eventManager.emit(
      EventTypes.UPDATE_TOTAL_WIN_VALUE,
      setCurrentFreeSpinsTotalWin(),
    );
  }

  private initPixiLayers() {
    this.application.stage.addChild(
      new Background(),
      new BottomContainer(),
      this.initSafeArea(),
      new FadeArea(),
    );
  }

  private initSafeArea(): SafeArea {
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);
    return safeArea;
  }

  private initGameView(slotConfig: ISlotConfig): GameView {
    const gameView = new GameView({
      winSlotsContainer: new WinSlotsContainer(),
      linesContainer: new LinesContainer(slotConfig.lines),
      reelsBackgroundContainer: new ReelsBackgroundContainer(),
      reelsContainer: this.reelsContainer,
      tintContainer: new TintContainer(),
      winLabelContainer: new WinLabelContainer(),
      winCountUpMessage: new WinCountUpMessage(),
      mysteryRevealContainer: this.mysteryRevealContainer,
      miniPayTableContainer: this.miniPayTableContainer,
    });

    gameView.interactive = true;
    gameView.on('mousedown', () => this.skipAnimations());
    gameView.on('touchstart', () => this.skipAnimations());
    this.initBuyFeature(slotConfig.lines, gameView);

    return gameView;
  }

  private initBuyFeature(lines: number[][], view: GameView): void {
    view.addChild(
      new BuyFeatureBtn(),
      new Backdrop(
        EventTypes.OPEN_BACKDROP_BG,
        EventTypes.CLOSE_BACKDROP_BG,
      ),
      new BuyFeaturePopup(lines),
      new BuyFeaturePopupConfirm(),
    );
  }

  private initEventListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
        if (setBrokenBuyFeature()) {
          setTimeout(() => {
            eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
            setBrokenBuyFeature(false);
          });
        }
        if (setBrokenGame()) {
          this.onBrokenGame();
        }
    });
    eventManager.addListener(
      EventTypes.RESET_SLOT_MACHINE,
      this.resetSlotMachine.bind(this),
    );
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.onStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.COUNT_UP_END,
      this.onCountUpEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.THROW_ERROR,
      this.handleError.bind(this),
    );
    eventManager.addListener(
      EventTypes.END_RETRIGGER_FEATURE,
      this.onRetriggerEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_BUY_FEATURE_ROUND,
      this.startBuyFeature.bind(this),
    );
  }

  public throwTimeoutError(): void {
    if (!setIsErrorMessage()) {
      setIsTimeoutErrorMessage(true)
    }
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    changeTo: SlotId;
    isFromBuyFeature: boolean;
  }) {
    setGameMode(settings.mode);
    setReelSetId(settings.isFromBuyFeature ? GameMode.BUY_FEATURE : settings.mode);

    const reelSet = setSlotConfig().reels.find(
      (reels) => reels.type === (settings.isFromBuyFeature ? GameMode.BUY_FEATURE : settings.mode),
    );

    const spinResult = getSpinResult({
      reelPositions: settings.reelPositions.slice(0, 5),
      reelSet: reelSet!,
      icons: setSlotConfig().icons,
    });

    this.miniPayTableContainer.setSpinResult(spinResult, settings.changeTo);
    eventManager.emit(EventTypes.CHANGE_REEL_SET, {
      reelSet: setSlotConfig().reels.find(
        (reels) => reels.type === (settings.isFromBuyFeature ? GameMode.BUY_FEATURE : settings.mode),
      ),
      reelPositions: settings.reelPositions,
    });
    if (settings.changeTo) {
      eventManager.emit(EventTypes.REPLACE_MYSTERY_SLOTS, settings.changeTo);
    }
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    if (settings.mode === GameMode.BASE_GAME) {
      setIsFreeSpinsWin(false);
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.settled,
      );
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        }),
      );
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      eventManager.emit(
        EventTypes.DISABLE_BUY_FEATURE_BTN,
        setIsContinueAutoSpinsAfterFeature(),
      );
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, false);
      this.setState(SlotMachineState.IDLE);

      AudioApi.stop({ type: ISongs.FreeSpinBGM_Intro });
      AudioApi.stop({ type: ISongs.FreeSpinBGM_Loop });
      AudioApi.play({ type: ISongs.BaseGameBGM_Base });
      AudioApi.play({ type: ISongs.BaseGameBGM_Melo, volume: 0 });
    } else if (isFreeSpinMode(settings.mode)) {
      setCurrentFreeSpinsTotalWin(this.nextResult!.winCoinAmount);
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setCurrentFreeSpinsTotalWin(),
      );
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.settled,
      );
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);

      eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
        text: i18n.t<string>('freeSpins.titl'),
        spins: '10',
        currentSpin: '0',
      });
      if (!setIsContinueAutoSpinsAfterFeature()) {
        setIsMessageBannerOpened({
          opened: true,
          callback: () => {
            this.setState(SlotMachineState.IDLE);
            setIsMessageBannerOpened({
              opened: false,
              callback: undefined,
            });
            eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
          },
        });
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: 'freeSpins.banner.title',
          titlePosition,
          titleStyles: titleStyle,
          subtitle: 'freeSpins.banner.subtitle',
          subtitlePosition,
          subtitleStyles: subtitleStyle,
          additionalText: 'freeSpins.banner.additional',
          additionalPosition,
          additionalStyles: additionalStyle,
          btnText: 'freeSpins.banner.start',
          callback: () => {
            this.setState(SlotMachineState.IDLE);
            setIsMessageBannerOpened({
              opened: false,
              callback: undefined,
            });
            eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
          },
        });
      } else {
        this.setState(SlotMachineState.IDLE);
      }

      // bgm
      const introDelay = Tween.createDelayAnimation(
        mappedAudioSprites[ISongs.FreeSpinBGM_Intro].duration,
      );
      AudioApi.stop({ type: ISongs.BaseGameBGM_Base });
      AudioApi.stop({ type: ISongs.BaseGameBGM_Melo });
      AudioApi.play({ type: ISongs.FreeSpinBGM_Intro });
      introDelay.addOnComplete(() => {
        const { volume } = AudioApi.getSoundByKey(ISongs.FreeSpinBGM_Intro);
        AudioApi.play({ type: ISongs.FreeSpinBGM_Loop, volume });
      });
      introDelay.start();
    }
  }

  private startBuyFeature(): void {
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.BUY_FEATURE,
      reelPositions: [0, 0, 0, 0, 0],
    });
  }

  // todo implement start free spins
  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);

    const mode = GameMode.FREE_SPINS;

    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode,
      reelPositions: [0, 0, 0, 0, 0],
    });
  }

  // todo implement start free spins
  private async endFreeSpins(): Promise<void> {
    const { reelPositions, gameMode } = {
      reelPositions: setCurrentBonus().originalReelPositions,
      gameMode: setCurrentBonus().isBuyFeature ? GameMode.BUY_FEATURE : GameMode.BASE_GAME
    };
    const changeTo = setCurrentBonus().mysterySymbol || setSlotConfig().reels.find(
      (reelSet) => {
        return reelSet.type === gameMode
      },
    )!.additionalReelSets[0][0];
    setFreeSpinsTotalWin(
      this.nextResult?.winCoinAmount! + 
      this.nextResult?.bet.wager.wagerStorage.previousTotalWinCoinAmount!);
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    AudioApi.play({ type: ISongs.TotalWinBanner, stopPrev: true });
    const callback = () => {
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.BASE_GAME,
        reelPositions,
        changeTo,
        isFromBuyFeature: setCurrentBonus().isBuyFeature
      });
      setIsMessageBannerOpened({
        opened: false,
        callback: undefined,
      });
      eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    if (!setIsContinueAutoSpinsAfterFeature()) {
      setIsMessageBannerOpened({
        opened: true,
        callback
      });
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: 'freeSpins.win.text',
        titlePosition: winTitlePosition,
        titleStyles: winTitleStyle,
        subtitle: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })}`,
        subtitlePosition: winSubtitlePosition,
        subtitleStyles: winSubtitleStyle,
        preventDefaultDestroy: true,
        callback,
      });
    } else {
      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callback();
      });
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: 'freeSpins.win.text',
        titlePosition: winTitlePosition,
        titleStyles: winTitleStyle,
        subtitle: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })}`,
        subtitlePosition: winSubtitlePosition,
        subtitleStyles: winSubtitleStyle,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
      });
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t<string>('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0].spinAnimation
      ?.getFakeRolling()
      .removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(current?: number, total?: number): void {
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      current,
      total
    );
  }

  private dynamicReelSetChange(): void {
    if (setReelSetId() !== setGameMode()) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find(
          (reels) => reels.type === setGameMode(),
        ),
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(setGameMode());
    }
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.isReadyForStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        this.removeErrorHandler();
        this.dynamicReelSetChange();

        eventManager.emit(
          EventTypes.SETUP_REEL_POSITIONS,
          this.nextResult.bet.outcomes[0].predicament.reelPositions,
          this.getScatterCount(this.nextResult.bet.spinResult),
          this.getAnticipationReelId(this.nextResult.bet.spinResult),
        );
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      this.isStopped = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(
        !isFreeSpinMode(setGameMode()) && !!isTurboSpin,
      );
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const mode = setGameMode();
    const bonus = setCurrentBonus();
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      let spinAnimation: SpinAnimation;
      if (mode === GameMode.BUY_FEATURE) {
        spinAnimation = reel.createBuyFeatureSpinAnimation(isTurboSpin);
      } else if (isFreeSpinMode(mode) && bonus.roundsPlayed === 0) {
        spinAnimation = reel.createMysterySpinAnimation(isTurboSpin);
      } else {
        spinAnimation = reel.createSpinAnimation(isTurboSpin);
      }
      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            this.dynamicReelSetChange();

            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.outcomes[0].predicament.reelPositions,
              this.getScatterCount(this.nextResult.bet.spinResult),
              this.getAnticipationReelId(this.nextResult.bet.spinResult),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i].isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() =>
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin),
          );
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private isFreeSpins(): boolean {
    return this.nextResult?.bet.outcomes[0].stateSnapshot.hasRewardedFreeSpins!
  }

  private onCountUpEnd(): void {
    const mode = setGameMode();
    if (this.isFreeSpins()) {
      if (mode === GameMode.BUY_FEATURE || mode === GameMode.BASE_GAME) {
        setLastRegularWinAmount(this.nextResult?.bet.betStorage.estimatedWinCoinAmount);
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.betStorage.estimatedWinCoinAmount);
        this.startFreeSpins();
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: normalizeCoins(this.nextResult?.bet.betStorage.estimatedWinCoinAmount), showCurrency: showCurrency(setCurrency()) }),
        );
      }
      if (isFreeSpinMode(mode)) {
        // on retrigger fs
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.wager.wagerStorage.totalWinCoinAmount + this.nextResult!.bet.wager.wagerStorage.previousTotalWinCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setCurrentFreeSpinsTotalWin(),
        );
        this.updateFreeSpinsAmount(
          setCurrentBonus().roundsPlayed,
          setCurrentBonus().rounds,
        );
        this.gameView.addChild(new RetriggerMessage());
        return;
      }
    } else {
      if (mode === GameMode.BASE_GAME) {
        setWinAmount(this.nextResult?.bet.betStorage.estimatedWinCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.betStorage.estimatedWinCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          this.nextResult?.balance.settled,
        );
      }
      if (isFreeSpinMode(mode)) {
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.wager.wagerStorage.totalWinCoinAmount + this.nextResult!.bet.wager.wagerStorage.previousTotalWinCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setCurrentFreeSpinsTotalWin(),
        );
      }
    }
    this.setState(SlotMachineState.IDLE);
  }

  private onRetriggerEnd(): void {
    this.setState(SlotMachineState.IDLE);
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        // eslint-disable-next-line no-plusplus
        if (spinResult[j].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT * 2].id === symbolId) currentCount++;

        if (currentCount >= count) minReelId = Math.min(minReelId, j);
      }
    });
    return minReelId;
  }

  private getScatterCount(spinResult: Icon[]): number[] {
    let count = 0;
    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (
          col.some((icon) => icon.id === SlotId.SC)
        ) {
          count += 1;
          return count;
        }
        return 0;
      })
      .value();
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const reelSet = setSlotConfig().reels.find(reel => reel.type === result.bet.wager.wagerSettings.gameMode)
    const spinResult = getSpinResult({
      reelPositions: result.bet.outcomes[0].predicament.reelPositions,
      reelSet: reelSet || setSlotConfig().reels[0],
      icons: setSlotConfig().icons,
    });

    result.bet.spinResult = spinResult;
    setPrevReelsPosition(result.bet.outcomes[0].predicament.reelPositions);
    setUserLastBetResult(result.bet);
    this.nextResult = result;
    setUserBalance(result.balance.settled);

    if (!isFreeSpinMode(setGameMode())) {
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.placed,
      );
    }

    if (result.bet.outcomes[0].stateSnapshot.hasRewardedFreeSpins) {
      const bonus = getBonusFromRewards(result.bet.wager, result.bet.outcomes[0]);
      setCurrentBonus({ ...bonus, mysterySymbol: result.bet.outcomes[0].predicament.mysterySymbol })
    }

    if (isFreeSpinMode(setGameMode())) {
      setCurrentBonus({
        ...setCurrentBonus(),
        state: result.bet.wager.state as BonusStatus,
        roundsPlayed: result.bet.wager.wagerStorage.roundsPlayed,
        rounds: result.bet.wager.wagerSettings.rounds,
        originalReelPositions: result.bet.wager.wagerSettings.originalReelPositions
      });
      this.updateFreeSpinsAmount(
        setCurrentBonus().roundsPlayed,
        undefined
      );

      eventManager.emit(EventTypes.SET_LAST_BET_RESULT_AFTER_FREE_SPINS);
    }
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    if (setIsErrorMessage()) {
      this.setState(SlotMachineState.IDLE);
      setIsSpinInProgress(false);
      setIsErrorMessage(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    } else {
      this.isSpinInProgressCallback();
      this.miniPayTableContainer.setSpinResult(
        this.nextResult!.bet.spinResult,
        this.nextResult!.bet.outcomes[0].predicament.mysterySymbol,
      );
      this.setState(SlotMachineState.MYSTERY);
    }
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x as number]!.slots[y as number]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(
      EventTypes.DISABLE_PAY_TABLE,
      isFreeSpinMode(setGameMode()) ? false : state === 0,
    );
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.betStorage.estimatedWinCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE ||
      setIsFreeSpinsWin() ||
      setIsContinueAutoSpinsAfterFeature(),
    );
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinMode(setGameMode())) {
        if (setCurrentBonus().gameMode === setGameMode() &&
          setCurrentBonus().rounds === setCurrentBonus().roundsPlayed
        ) {
          this.endFreeSpins();
        } else {
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),


            setCurrentBonus().roundsPlayed === 0 ? 0 : 500,
          );
        }
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.MYSTERY) {
      if (this.nextResult?.bet.outcomes[0].predicament.mysterySymbol) {
        const animation = this.mysteryRevealContainer.createMystery(
          this.nextResult!.bet.spinResult,
          setGameMode(),
        );
        animation.addOnComplete(() => this.setState(SlotMachineState.JINGLE));
        animation.addOnStart(() => {
          eventManager.emit(
            EventTypes.REPLACE_MYSTERY_SLOTS,
            this.nextResult?.bet.outcomes[0].predicament.mysterySymbol,
          )
        },
        );
        animation.start();
      } else {
        this.setState(SlotMachineState.JINGLE);
      }
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.nextResult?.bet.outcomes[0].stateSnapshot.hasRewardedFreeSpins) {
        const jingleDelay = Tween.createDelayAnimation(
          mappedAudioSprites[ISongs.FeatureTrigger].duration,
        );
        jingleDelay.addOnStart(() => {
          AudioApi.play({ type: ISongs.FeatureTrigger, stopPrev: true });
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(
          EventTypes.START_WIN_ANIMATION,
          this.nextResult!,
          false,
        );
      } else {
        if (!isFreeSpinMode(GameMode.FREE_SPINS)) {
          eventManager.emit(
            EventTypes.UPDATE_USER_BALANCE,
            this.nextResult?.balance.settled,
          );
        }
        this.setState(SlotMachineState.IDLE);
      }
    }
  }
}

export default SlotMachine;
