import type React from 'react';

const SkipSvg = () => {
  return (
    <svg width="328" height="328" viewBox="0 0 328 328" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M164 312C245.738 312 312 245.738 312 164C312 82.2619 245.738 16 164 16C82.2619 16 16 82.2619 16 164C16 245.738 82.2619 312 164 312Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M208.4 82.6C205.335 82.6 202.85 85.0848 202.85 88.15V241.7C202.85 244.765 205.335 247.25 208.4 247.25H226.9C229.965 247.25 232.45 244.765 232.45 241.7V88.15C232.45 85.0848 229.965 82.6 226.9 82.6H208.4Z"
        fill="white"
        className="svg-color"
      />
      <path
        d="M93.7 93.7377C93.7 89.1573 98.9351 86.5485 102.592 89.3067L196.975 160.494C199.919 162.715 199.919 167.135 196.975 169.356L102.592 240.543C98.9351 243.301 93.7 240.693 93.7 236.112V93.7377Z"
        fill="white"
        className="svg-color"
      />
    </svg>
  );
};

export default SkipSvg;
