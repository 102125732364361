import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, RangeSlider, Toggle } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { PopupOpeningTypes } from '../../global.d';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setIsAutoSpins,
  setIsOpenAutoplayPopup,
  setIsPopupOpeningInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnBalanceIncreaseSliderValue,
  setStopOnWinExceeds,
  setStopOnWinExceedsSliderValue,
  setUserBalance,
} from '../../gql/cache';
import type { ISlotConfig } from '../../gql/d';
import { getAutoSpinsSettingsGql, getBetAmountGql } from '../../gql/query';
import { normalizeCoins, showCurrency } from '../../utils';

import styles from './autoPlaySettings.module.scss';
import type { IAutoPlaySettingsProps } from './d';

const AutoPlaySettings: React.FC<IAutoPlaySettingsProps> = (props) => {
  const { t } = useTranslation();
  const { className = '' } = props;
  const {
    clientSlotSettings: { autoplaySettings },
  } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const { data } = useQuery<{
    autoSpinsAmount: number;
    isStopOnAnyWin: boolean;
    isStopOnFeatureWin: boolean;
    isStopOnWinExceeds: boolean;
    isStopOnBalanceIncrease: boolean;
    isStopOnBalanceDecrease: boolean;
    stopOnWinExceeds: number;
    stopOnBalanceIncrease: number;
    stopOnBalanceDecrease: number;
  }>(getAutoSpinsSettingsGql);
  useEffect(() => {
    setAutoSpinsAmount(config.autoplay.autoSpinValues[3]);
  }, []);

  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);
  // TODO to be removed
  const currency = userBalance.currency;
  const balanceAmount = userBalance.amount;
  const { betAmount } = dataBet!;
  const {
    autoSpinsAmount,
    isStopOnAnyWin,
    isStopOnFeatureWin,
    stopOnWinExceeds,
    stopOnBalanceIncrease,
    stopOnBalanceDecrease,
    isStopOnWinExceeds,
    isStopOnBalanceIncrease,
    isStopOnBalanceDecrease,
  } = data!;

  const winExceedsSliderValue = useReactiveVar<number>(setStopOnWinExceedsSliderValue);
  const balanceIncreaseSliderValue = useReactiveVar<number>(setStopOnBalanceIncreaseSliderValue);
  const onAutoSpinsAmountChange = useCallback((value: number) => {
    setAutoSpinsAmount(value);
  }, []);

  const coinMultiplier = setSlotConfig().slotSettings.globalCoinAmountMultiplier;

  const handleStartAutoplay = useCallback(() => {
    if (setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP) {
      AudioApi.play({ type: ISongs.SFX_UI_General });
      setLastRegularWinAmount(0);
      setAutoSpinsLeft(autoSpinsAmount);
      setIsAutoSpins(true);
      setAutoSpinsStartBalance(balanceAmount);
      setIsOpenAutoplayPopup(false);
    }
  }, [balanceAmount, autoSpinsAmount]);

  useEffect(() => {
    if (
      autoplaySettings.conditions.stopIfSingleWinExceeds.multipliers[winExceedsSliderValue - 1]! * betAmount !==
      stopOnWinExceeds
    ) {
      setStopOnWinExceeds(betAmount);
      setStopOnWinExceedsSliderValue(1);
    }
    if (
      autoplaySettings.conditions.stopIfBalanceIncreasesBy.multipliers[balanceIncreaseSliderValue - 1]! * betAmount !==
      stopOnBalanceIncrease
    ) {
      setStopOnBalanceIncrease(betAmount);
      setStopOnBalanceIncreaseSliderValue(1);
      setStopOnBalanceDecrease(betAmount);
    }
  }, []);

  return (
    <div className={styles['container']}>
      <div className={styles['wrap']}>
        <div className={`${styles['autoplay-settings']} ${className}`}>
          <div className={styles['autoplay-settings__col']}>
            <div className={`${styles['autoplay-settings__item']} ${styles['btns']}`}>
              <div className={styles['label']}>{t('autoPlay.spin')}</div>
              <div className="btn-group">
                {(autoplaySettings.options as number[]).map((item) => (
                  <Button
                    className="uiBtn"
                    intent="autoPlaySettingBtn"
                    key={item}
                    isActive={autoSpinsAmount === item}
                    title={`${item}`}
                    onClick={() => {
                      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
                      onAutoSpinsAmountChange(item);
                    }}
                  />
                ))}
              </div>
            </div>
            {autoplaySettings.conditions.stopIfFeatureIsWon.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles['controls']}>
                  <Toggle
                    className={styles['toggle'] as string}
                    checked={isStopOnFeatureWin}
                    id="stop_on_feature_win"
                    onChange={() => {
                      setIsStopOnFeatureWin(!isStopOnFeatureWin);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles['label']}>{t('autoPlay.featureWin')}</div>
                </div>
              </div>
            )}
            {autoplaySettings.conditions.stopOnAnyWin.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles['controls']}>
                  <Toggle
                    className={styles['toggle'] as string}
                    checked={isStopOnAnyWin}
                    id="stop_on_won"
                    onChange={() => {
                      setIsStopOnAnyWin(!isStopOnAnyWin);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles['label']}>{t('autoPlay.stop')}</div>
                </div>
              </div>
            )}
          </div>
          <div className={styles['autoplay-settings__col']}>
            {autoplaySettings.conditions.stopIfSingleWinExceeds.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles['controls']}>
                  <Toggle
                    className={styles['toggle'] as string}
                    checked={isStopOnWinExceeds}
                    id="stop_on_win_exceeds"
                    onChange={() => {
                      setIsStopOnWinExceeds(!isStopOnWinExceeds);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles['label']}>
                    {t('autoPlay.singleWin')}{' '}
                    <span className={styles['value']}>
                      {formatNumber({
                        currency,
                        value: normalizeCoins(stopOnWinExceeds),
                        showCurrency: showCurrency(currency),
                      })}
                    </span>
                    <RangeSlider
                      disabled={!isStopOnWinExceeds}
                      className={styles['slider'] as string}
                      min={1}
                      max={autoplaySettings.conditions.stopIfSingleWinExceeds.multipliers.length}
                      value={winExceedsSliderValue}
                      onChange={(v: number) => {
                        setStopOnWinExceedsSliderValue(v);
                        setStopOnWinExceeds(
                          setCoinAmount() *
                            autoplaySettings.conditions.stopIfSingleWinExceeds.multipliers[v - 1]! *
                            coinMultiplier,
                        );
                        AudioApi.play({ type: ISongs.SFX_UI_BetChange });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {autoplaySettings.conditions.stopIfBalanceIncreasesBy.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles['controls']}>
                  <Toggle
                    className={styles['toggle'] as string}
                    checked={isStopOnBalanceIncrease}
                    id="stop_on_balance_increase"
                    onChange={() => {
                      setIsStopOnBalanceIncrease(!isStopOnBalanceIncrease);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles['label']}>
                    {t('autoPlay.balanceUp')}{' '}
                    <span className={styles['value']}>{`${formatNumber({
                      currency,
                      value: normalizeCoins(stopOnBalanceIncrease),
                      showCurrency: showCurrency(currency),
                    })}`}</span>
                    <RangeSlider
                      disabled={!isStopOnBalanceIncrease}
                      className={styles['slider'] as string}
                      min={1}
                      max={autoplaySettings.conditions.stopIfBalanceIncreasesBy.multipliers.length}
                      value={balanceIncreaseSliderValue}
                      onChange={(v: number) => {
                        setStopOnBalanceIncreaseSliderValue(v);
                        setStopOnBalanceIncrease(
                          setCoinAmount() *
                            autoplaySettings.conditions.stopIfBalanceIncreasesBy.multipliers[v - 1]! *
                            coinMultiplier,
                        );
                        AudioApi.play({ type: ISongs.SFX_UI_BetChange });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {autoplaySettings.conditions.stopIfBalanceDecreasesBy.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles['controls']}>
                  <Toggle
                    className={styles['toggle'] as string}
                    checked={isStopOnBalanceDecrease}
                    id="stop_on_balance_decrease"
                    onChange={() => {
                      setIsStopOnBalanceDecrease(!isStopOnBalanceDecrease);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles['label']}>
                    {t('autoPlay.balanceDown')}{' '}
                    <span className={styles['value']}>{`${formatNumber({
                      currency,
                      value: normalizeCoins(stopOnBalanceDecrease),
                      showCurrency: showCurrency(currency),
                    })}`}</span>
                    <RangeSlider
                      disabled={!isStopOnBalanceDecrease}
                      className={styles['slider'] as string}
                      min={betAmount}
                      max={balanceAmount / setCoinValue()}
                      value={stopOnBalanceDecrease}
                      onChange={(v: number) => {
                        setStopOnBalanceDecrease(v);
                        AudioApi.play({ type: ISongs.SFX_UI_BetChange });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Button intent="confirm" onClick={handleStartAutoplay} disabled={autoSpinsAmount === 0} />
      </div>
    </div>
  );
};

export default AutoPlaySettings;
