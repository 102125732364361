import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.symA },
  [SlotId.B]: { default: ResourceTypes.symB },
  [SlotId.C]: { default: ResourceTypes.symC },
  [SlotId.D]: { default: ResourceTypes.symD },
  [SlotId.E]: { default: ResourceTypes.symE },
  [SlotId.F]: { default: ResourceTypes.symF },
  [SlotId.G]: { default: ResourceTypes.symG },
  [SlotId.H]: { default: ResourceTypes.symH },
  [SlotId.I]: { default: ResourceTypes.symI },
  [SlotId.J]: { default: ResourceTypes.symJ },
  [SlotId.K]: { default: ResourceTypes.symK },
  [SlotId.MS]: {
    default: ResourceTypes.symMs1,
    freespin: ResourceTypes.symMs2,
  },
  [SlotId.WL]: { default: ResourceTypes.symWl },
  [SlotId.SC]: { default: ResourceTypes.symSc },
};

export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.blurSymA },
  [SlotId.B]: { default: ResourceTypes.blurSymB },
  [SlotId.C]: { default: ResourceTypes.blurSymC },
  [SlotId.D]: { default: ResourceTypes.blurSymD },
  [SlotId.E]: { default: ResourceTypes.blurSymE },
  [SlotId.F]: { default: ResourceTypes.blurSymF },
  [SlotId.G]: { default: ResourceTypes.blurSymG },
  [SlotId.H]: { default: ResourceTypes.blurSymH },
  [SlotId.I]: { default: ResourceTypes.blurSymI },
  [SlotId.J]: { default: ResourceTypes.blurSymJ },
  [SlotId.K]: { default: ResourceTypes.blurSymK },
  [SlotId.MS]: {
    default: ResourceTypes.symMs1,
    freespin: ResourceTypes.symMs2,
  },
  [SlotId.WL]: { default: ResourceTypes.symWl },
  [SlotId.SC]: { default: ResourceTypes.symSc },
};

export const MAPPED_SYMBOLS_ANIMATIONS: Record<SlotId, { default: string; freespin?: string }> = {
  [SlotId.A]: { default: 'aAnimation' },
  [SlotId.B]: { default: 'bAnimation' },
  [SlotId.C]: { default: 'cAnimation' },
  [SlotId.D]: { default: 'dAnimation' },
  [SlotId.E]: { default: 'eAnimation' },
  [SlotId.F]: { default: 'fAnimation' },
  [SlotId.G]: { default: 'gAnimation' },
  [SlotId.H]: { default: 'hAnimation' },
  [SlotId.I]: { default: 'iAnimation' },
  [SlotId.J]: { default: 'jAnimation' },
  [SlotId.K]: { default: 'kAnimation' },
  [SlotId.MS]: {
    default: 'mystery01Animation',
    freespin: 'mystery02Animation',
  },
  [SlotId.WL]: { default: 'wildAnimation' },
  [SlotId.SC]: { default: 'scatterAnimation' },
};

export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.symA, src: Resources.getSource(ResourceTypes.symA) },
  { name: ResourceTypes.symB, src: Resources.getSource(ResourceTypes.symB) },
  { name: ResourceTypes.symC, src: Resources.getSource(ResourceTypes.symC) },
  { name: ResourceTypes.symD, src: Resources.getSource(ResourceTypes.symD) },
  { name: ResourceTypes.symE, src: Resources.getSource(ResourceTypes.symE) },
  { name: ResourceTypes.symF, src: Resources.getSource(ResourceTypes.symF) },
  { name: ResourceTypes.symG, src: Resources.getSource(ResourceTypes.symG) },
  { name: ResourceTypes.symH, src: Resources.getSource(ResourceTypes.symH) },
  { name: ResourceTypes.symI, src: Resources.getSource(ResourceTypes.symI) },
  { name: ResourceTypes.symJ, src: Resources.getSource(ResourceTypes.symJ) },
  { name: ResourceTypes.symK, src: Resources.getSource(ResourceTypes.symK) },
  { name: ResourceTypes.symWl, src: Resources.getSource(ResourceTypes.symWl) },
  { name: ResourceTypes.symMs1, src: Resources.getSource(ResourceTypes.symMs1) },
  { name: ResourceTypes.symMs2, src: Resources.getSource(ResourceTypes.symMs2) },
  { name: ResourceTypes.symSc, src: Resources.getSource(ResourceTypes.symSc) },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.shortLine,
    src: Resources.getSource(ResourceTypes.shortLine),
  },
  {
    name: ResourceTypes.mediumLine,
    src: Resources.getSource(ResourceTypes.mediumLine),
  },
  {
    name: ResourceTypes.largeLine,
    src: Resources.getSource(ResourceTypes.largeLine),
  },
  {
    name: ResourceTypes.reelsLight1,
    src: Resources.getSource(ResourceTypes.reelsLight1),
  },
  {
    name: ResourceTypes.reelsLight2,
    src: Resources.getSource(ResourceTypes.reelsLight2),
  },
  {
    name: ResourceTypes.reelsLight3,
    src: Resources.getSource(ResourceTypes.reelsLight3),
  },
  {
    name: ResourceTypes.reelsLight4,
    src: Resources.getSource(ResourceTypes.reelsLight4),
  },
  {
    name: ResourceTypes.reelsLight5,
    src: Resources.getSource(ResourceTypes.reelsLight5),
  },
  {
    name: ResourceTypes.reelsLight1Free,
    src: Resources.getSource(ResourceTypes.reelsLight1Free),
  },
  {
    name: ResourceTypes.reelsLight2Free,
    src: Resources.getSource(ResourceTypes.reelsLight2Free),
  },
  {
    name: ResourceTypes.reelsLight3Free,
    src: Resources.getSource(ResourceTypes.reelsLight3Free),
  },
  {
    name: ResourceTypes.reelsLight4Free,
    src: Resources.getSource(ResourceTypes.reelsLight4Free),
  },
  {
    name: ResourceTypes.reelsLight5Free,
    src: Resources.getSource(ResourceTypes.reelsLight5Free),
  },
  {
    name: ResourceTypes.messageBanner,
    src: Resources.getSource(ResourceTypes.messageBanner),
  },
  {
    name: 'ui',
    src: '/images/ui/ui.json',
  },
  {
    name: 'fAnimation',
    src: '/animations/symbol_win/ace_anima.json',
  },
  {
    name: 'cAnimation',
    src: '/animations/symbol_win/cheetah_anima.json',
  },
  {
    name: 'eAnimation',
    src: '/animations/symbol_win/giraffe_anima.json',
  },
  {
    name: 'iAnimation',
    src: '/animations/symbol_win/jack_anima.json',
  },
  {
    name: 'gAnimation',
    src: '/animations/symbol_win/king_anima.json',
  },
  {
    name: 'wildAnimation',
    src: '/animations/symbol_win/lion_anima.json',
  },
  {
    name: 'aAnimation',
    src: '/animations/symbol_win/elephant_anima.json',
  },
  {
    name: 'mystery01Animation',
    src: '/animations/symbol_win/Mystery01_anima.json',
  },
  {
    name: 'mystery02Animation',
    src: '/animations/symbol_win/Mystery02_anima.json',
  },
  {
    name: 'kAnimation',
    src: '/animations/symbol_win/nine_anima.json',
  },
  {
    name: 'scatterAnimation',
    src: '/animations/symbol_win/sun_anima.json',
  },
  {
    name: 'hAnimation',
    src: '/animations/symbol_win/queen_anima.json',
  },
  {
    name: 'bAnimation',
    src: '/animations/symbol_win/buffalo_anima.json',
  },
  {
    name: 'dAnimation',
    src: '/animations/symbol_win/rhino_anima.json',
  },
  {
    name: 'jAnimation',
    src: '/animations/symbol_win/ten_anima.json',
  },
  {
    name: 'MysterySymbol_Add_Anim',
    src: '/animations/adding_mystery/MysterySymbol_Add_Anim.json',
  },
  {
    name: ResourceTypes.reelLogo,
    src: Resources.getSource(ResourceTypes.reelLogo),
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.introLogo,
    src: Resources.getSource(ResourceTypes.introLogo),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.slotFreeSpinBg,
    src: Resources.getSource(ResourceTypes.slotFreeSpinBg),
  },
  {
    name: ResourceTypes.slotFreeSpinCounterBg,
    src: Resources.getSource(ResourceTypes.slotFreeSpinCounterBg),
  },
  {
    name: ResourceTypes.buyFeatureBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureBtn),
  },
  {
    name: ResourceTypes.buyFeatureBtnNotActive,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnNotActive),
  },
  {
    name: ResourceTypes.buyFeatureBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureInput,
    src: Resources.getSource(ResourceTypes.buyFeatureInput),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtn),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.blurSymA,
    src: Resources.getSource(ResourceTypes.blurSymA),
  },
  {
    name: ResourceTypes.blurSymB,
    src: Resources.getSource(ResourceTypes.blurSymB),
  },
  {
    name: ResourceTypes.blurSymC,
    src: Resources.getSource(ResourceTypes.blurSymC),
  },
  {
    name: ResourceTypes.blurSymD,
    src: Resources.getSource(ResourceTypes.blurSymD),
  },
  {
    name: ResourceTypes.blurSymE,
    src: Resources.getSource(ResourceTypes.blurSymE),
  },
  {
    name: ResourceTypes.blurSymF,
    src: Resources.getSource(ResourceTypes.blurSymF),
  },
  {
    name: ResourceTypes.blurSymG,
    src: Resources.getSource(ResourceTypes.blurSymG),
  },
  {
    name: ResourceTypes.blurSymH,
    src: Resources.getSource(ResourceTypes.blurSymH),
  },
  {
    name: ResourceTypes.blurSymI,
    src: Resources.getSource(ResourceTypes.blurSymI),
  },
  {
    name: ResourceTypes.blurSymJ,
    src: Resources.getSource(ResourceTypes.blurSymJ),
  },
  {
    name: ResourceTypes.blurSymK,
    src: Resources.getSource(ResourceTypes.blurSymK),
  },
];
