import type React from 'react';

const RightSvg = (): JSX.Element => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.0083 90.0084C55.3304 92.6863 55.3304 97.028 58.0083 99.7059C60.6862 102.384 65.0279 102.384 67.7057 99.7059L90.5629 76.8487C92.5713 74.8403 93.0734 71.896 92.0692 69.4197C91.7345 68.5942 91.2323 67.8207 90.5629 67.1513C90.5619 67.1503 90.5608 67.1492 90.5598 67.1482L67.7057 44.2941C65.0279 41.6163 60.6862 41.6163 58.0083 44.2941C55.3304 46.972 55.3304 51.3137 58.0083 53.9916L76.0167 72L58.0083 90.0084Z"
        fill="white"
      />
    </svg>
  );
};

export default RightSvg;
