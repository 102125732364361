import type React from 'react';

const HistorySvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.9997 34.4763C51.2759 34.4763 34.4759 51.2762 34.4759 72.0001C34.4759 92.7239 51.2759 109.524 71.9997 109.524C92.7235 109.524 109.524 92.7239 109.524 72.0001C109.524 51.2762 92.7235 34.4763 71.9997 34.4763ZM24.1902 72.0001C24.1902 45.5956 45.5952 24.1906 71.9997 24.1906C98.4042 24.1906 119.809 45.5956 119.809 72.0001C119.809 98.4045 98.4042 119.81 71.9997 119.81C45.5952 119.81 24.1902 98.4045 24.1902 72.0001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.7141 47.238C72.8701 47.238 75.4284 49.7963 75.4284 52.9522V68.5713H91.0475C94.2034 68.5713 96.7618 71.1297 96.7618 74.2856C96.7618 77.4415 94.2034 79.9999 91.0475 79.9999H69.7141C66.5582 79.9999 63.9999 77.4415 63.9999 74.2856V52.9522C63.9999 49.7963 66.5582 47.238 69.7141 47.238Z"
        fill="white"
      />
    </svg>
  );
};

export default HistorySvg;
