import _ from 'lodash';

import type { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BaseGameBGM_Base = '011_01_BaseGameBGM_Base',
  BaseGameBGM_Melo = '011_02_BaseGameBGM_Melo',
  FreeSpinBGM_Intro = '011_03_FreeSpinBGM_Intro',
  FreeSpinBGM_Loop = '011_03_FreeSpinBGM_Loop',
  BigWin_Intro = '011_04_BigWin_Intro',
  BigWin_Loop = '011_04_BigWin_Loop',
  BigWin_End = '011_05_BigWin_End',
  BigWin_Start = '011_06_BigWin_Start',
  Win_Loop = '011_07_Win_Loop',
  Win_End = '011_08_Win_End',
  FeatureTrigger = '011_09_FeatureTrigger',
  TotalWinBanner = '011_10_TotalWinBanner',
  Scatter_01 = '011_11_Scatter_01',
  Scatter_02 = '011_12_Scatter_02',
  Scatter_03 = '011_13_Scatter_03',
  Scatter_04 = '011_14_Scatter_04',
  Scatter_05 = '011_15_Scatter_05',
  LongSpin = '011_16_LongSpin',
  Mystery_Nom = '011_17_Mystery_Nom',
  Mystery_SP = '011_18_Mystery_SP',
  Mystery_Add = '011_19_Mystery_Add',
  SFX_BuyFeature = 'SFX_BuyFeature',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_BIG = 'SFX_WIN_BIG',
  SFX_WIN_EPIC = 'SFX_WIN_EPIC',
  SFX_WIN_GREAT = 'SFX_WIN_GREAT',
  SFX_WIN_MEGA = 'SFX_WIN_MEGA',
}

export const audioSprite: TAudioSprite = {
  [ISongs.BaseGameBGM_Base]: [0, 80842.13151927438, true],
  [ISongs.BaseGameBGM_Melo]: [82000, 80842.13151927438, true],
  [ISongs.FreeSpinBGM_Intro]: [164000, 18857.188208616775],
  [ISongs.FreeSpinBGM_Loop]: [184000, 42857.23356009072, true],
  [ISongs.BigWin_Intro]: [228000, 1564.036281179142],
  [ISongs.BigWin_Loop]: [231000, 75789.52380952382, true],
  [ISongs.BigWin_End]: [308000, 3569.4784580499004],
  [ISongs.BigWin_Start]: [313000, 3623.764172335598],
  [ISongs.Win_Loop]: [318000, 947.6643990929574, true],
  [ISongs.Win_End]: [320000, 1002.8117913832375],
  [ISongs.FeatureTrigger]: [323000, 879.8639455782222],
  [ISongs.TotalWinBanner]: [325000, 1847.4376417233316],
  [ISongs.Scatter_01]: [328000, 793.8321995465003],
  [ISongs.Scatter_02]: [330000, 802.5396825397024],
  [ISongs.Scatter_03]: [332000, 808.3446712018372],
  [ISongs.Scatter_04]: [334000, 786.5759637188035],
  [ISongs.Scatter_05]: [336000, 1113.1065759637409],
  [ISongs.LongSpin]: [339000, 2666.712018140572],
  [ISongs.Mystery_Nom]: [343000, 644.7165532879922],
  [ISongs.Mystery_SP]: [345000, 2061.5873015872808],
  [ISongs.Mystery_Add]: [349000, 333.3333333333144],
  [ISongs.SFX_BuyFeature]: [351000, 666.6666666666856],
  [ISongs.SFX_SM_CountUp_End]: [353000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [355000, 399.77324263037417, true],
  [ISongs.SFX_UI_AutoSpin]: [357000, 320.4988662131427],
  [ISongs.SFX_UI_BetChange]: [359000, 32.108843537400844],
  [ISongs.SFX_UI_Close]: [361000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [363000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [365000, 151.79138321997243],
  [ISongs.SFX_UI_MaxBet]: [367000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [369000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [371000, 249.61451247168043],
  [ISongs.SFX_UI_SpinStop]: [373000, 151.02040816327644],
  [ISongs.SFX_WIN_BIG]: [375000, 1635.5555555555839],
  [ISongs.SFX_WIN_EPIC]: [378000, 2245.079365079391],
  [ISongs.SFX_WIN_GREAT]: [382000, 2134.784580498888],
  [ISongs.SFX_WIN_MEGA]: [386000, 1708.1179138322113],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BaseGameBGM_Base]: 0.3,
  [ISongs.BaseGameBGM_Melo]: 0.2,
  [ISongs.FreeSpinBGM_Intro]: 0.3,
  [ISongs.FreeSpinBGM_Loop]: 0.2,
  [ISongs.BigWin_Intro]: 0.3,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.BigWin_Start]: 0.3,
  [ISongs.Win_Loop]: 0.5,
  [ISongs.Win_End]: 0.7,
  [ISongs.FeatureTrigger]: 0.3,
  [ISongs.TotalWinBanner]: 0.5,
  [ISongs.Scatter_01]: 0.5,
  [ISongs.Scatter_02]: 0.5,
  [ISongs.Scatter_03]: 0.5,
  [ISongs.Scatter_04]: 0.5,
  [ISongs.Scatter_05]: 0.5,
  [ISongs.LongSpin]: 0.3,
  [ISongs.Mystery_Nom]: 0.5,
  [ISongs.Mystery_SP]: 0.5,
  [ISongs.Mystery_Add]: 0.5,
  [ISongs.SFX_BuyFeature]: 0.4,
  [ISongs.SFX_SM_CountUp_End]: 0.4,
  [ISongs.SFX_SM_CountUp_Loop]: 0.4,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.4,
  [ISongs.SFX_UI_SpinStop]: 0.4,
  [ISongs.SFX_WIN_BIG]: 0.3,
  [ISongs.SFX_WIN_EPIC]: 0.3,
  [ISongs.SFX_WIN_GREAT]: 0.3,
  [ISongs.SFX_WIN_MEGA]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
