import { gql } from '@apollo/client';

import { BET, OUTCOME, WAGER } from './fragments';

export const authGql = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      sessionId
      playerIdToken
    }
  }
`;

export const placeBetGql = gql`
  ${BET}
  ${WAGER}
  ${OUTCOME}
  mutation PlaceBet($input: PlaceBetInput!) {
    placeBet(input: $input) {
      bet {
        ...bet
      }
      balance {
        settled {
          currency
          amount
        }
        placed {
          currency
          amount
        }
      }
      winCoinAmount
    }
  }
`;

export const buyFeatureGql = gql`
  ${WAGER}
  mutation assignBonus($input: BuyFeatureInput!) {
    buyFeature(input: $input) {
      wager {
        ...wager
      }
      balance {
        settled {
          currency
          amount
        }
        placed {
          currency
          amount
        }
      }
    }
  }
`;
