import * as PIXI from 'pixi.js';
import { TextStyle } from 'pixi.js';

import { Colors, Variables } from '../../config/variables';

const winCounterStyle = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 50,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

export const miniPayTableStyle = {
  fontSize: 28,
  lineHeight: 50,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
};
export const miniPayTableAdditionalTextStyle = new TextStyle({
  fontSize: 28,
  lineHeight: 30,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
  wordWrap: true,
  wordWrapWidth: 220,
  breakWords: true,
  align: 'center',
});

export const clockTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const gameNameTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});
export const bottomContainerTextStyle = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fill: '#ffffff',
    fontSize: 26,
    lineHeight: 26,
    trim: true,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

export const bigWinStyles = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  align: 'center',
  fill: [0xffffff, 0x81bfd9],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontSize: 80,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 10,
  stroke: '#002241',
  strokeThickness: 10,
  trim: true,
});

export const megaWinStyles = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  align: 'center',
  fill: [0xffffff, 0xff87a3],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontSize: 80,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 10,
  stroke: '#410014',
  strokeThickness: 10,
  trim: true,
});

export const greatWinStyles = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  align: 'center',
  fill: [0xffffff, 0x6ff032],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontSize: 80,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 10,
  stroke: '#054100',
  strokeThickness: 10,
  trim: true,
});

export const epicWinStyles = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  align: 'center',
  fill: [0xffffff, 0xd981d5],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontSize: 80,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 10,
  stroke: '#68008D',
  strokeThickness: 10,
  trim: true,
});

export const winValueStyles = new PIXI.TextStyle(winCounterStyle);

// BuyFeature Button Text
export const buyFeatureTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  align: 'center',
  fill: '#ffaa00',
  fontSize: 25,
  stroke: '#460211',
  strokeThickness: 3,
  whiteSpace: 'normal',
  wordWrap: true,
  miterLimit: 10,
});
export const buyFeatureHoverTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fill: '#ffff54',
  fontSize: 26,
  stroke: '#460211',
  strokeThickness: 3,
  whiteSpace: 'normal',
  wordWrap: true,
  miterLimit: 10,
});
export const buyFeatureClickTextStyle = buyFeatureTextStyle;
export const buyFeatureDisableTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fill: '#999999',
  fontSize: 25,
  stroke: '#000000',
  strokeThickness: 3,
  whiteSpace: 'normal',
  wordWrap: true,
  miterLimit: 10,
});

export const autoPlayCounterTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  stroke: 'black',
  strokeThickness: 4,
  fontSize: 25,
  whiteSpace: 'normal',
  align: 'center',
  fill: Colors.GAME_COLOR,
});

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };
