import type React from 'react';

const AutoplayOnSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M57.4788 42.9414C61.8487 40.7497 66.8007 39.504 72.0319 39.504C89.9713 39.504 104.528 54.0607 104.528 72H97.5046C95.5268 72 94.3979 74.258 95.5848 75.8402L108.512 93.0728C109.472 94.3525 111.392 94.3525 112.352 93.0728L125.279 75.8402C126.466 74.258 125.337 72 123.359 72H116.336C116.336 47.5393 96.4926 27.696 72.0319 27.696C64.9111 27.696 58.1512 29.3943 52.1851 32.3866C49.2704 33.8484 48.0927 37.3962 49.5545 40.3109C51.0164 43.2255 54.5642 44.4033 57.4788 42.9414Z"
        fill="white"
      />
      <path
        d="M27.7279 72H21.4406C19.4628 72 18.3339 69.7419 19.5208 68.1598L32.4481 50.9272C33.4081 49.6475 35.3278 49.6475 36.2878 50.9272L49.2152 68.1598C50.402 69.7419 49.2731 72 47.2953 72H39.536C39.536 89.9393 54.0926 104.496 72.0319 104.496C77.4324 104.496 82.5191 103.181 86.9797 100.87C89.8747 99.3698 93.4378 100.501 94.938 103.396C96.4381 106.291 95.3074 109.854 92.4123 111.354C86.3128 114.515 79.3675 116.304 72.0319 116.304C47.5713 116.304 27.7279 96.4607 27.7279 72Z"
        fill="white"
      />
      <path
        d="M66.2192 57.2007C64.6339 56.0382 62.3999 57.1702 62.3999 59.1361V84.8637C62.3999 86.8295 64.6339 87.9616 66.2192 86.7991L83.7608 73.9352C85.0684 72.9763 85.0684 71.0234 83.7608 70.0645L66.2192 57.2007Z"
        fill="white"
      />
    </svg>
  );
};

export default AutoplayOnSvg;
