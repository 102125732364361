import * as PIXI from 'pixi.js';

export const RETRIGGER_MESSAGE_POSITION_X = 0;
export const RETRIGGER_MESSAGE_POSITION_Y = 0;

export const RETRIGGER_MESSAGE_ZOOM_SCALE = 1.2;
export const RETRIGGER_MESSAGE_ZOOM_DURATION = 500;
export const RETRIGGER_MESSAGE_DELAY_DURATION = 3000;
export const RETRIGGER_MESSAGE_FADEOUT_DURATION = 500;

export const retriggerMessageStyle = new PIXI.TextStyle({
  dropShadow: true,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  fill: ['#ffb3b3', '#ff0000', '#a30000', '#ffd738', '#ff3728', '#ff007e', '#ac008a', '#5b0038', '#ffffff'],
  fillGradientStops: [0.2, 0.38, 0.5, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 120,
  stroke: '#ffffff',
  strokeThickness: 8,
});
