import SlotMachine from '..';
import { EventTypes } from '../../global.d';
import i18n from '../../i18next';
import { TweenProperties } from '../animations/d';
import { createZoomAnimation } from '../animations/helper';
import Tween from '../animations/tween';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/container';
import { eventManager } from '../config';

import {
  RETRIGGER_MESSAGE_DELAY_DURATION,
  RETRIGGER_MESSAGE_FADEOUT_DURATION,
  RETRIGGER_MESSAGE_POSITION_X,
  RETRIGGER_MESSAGE_POSITION_Y,
  RETRIGGER_MESSAGE_ZOOM_DURATION,
  RETRIGGER_MESSAGE_ZOOM_SCALE,
  retriggerMessageStyle,
} from './config';

class RetriggerMessage extends ViewContainer {
  private text: TextField;

  constructor() {
    super();

    this.text = new TextField(i18n.t<string>('retrigger'), 800, 250, retriggerMessageStyle);
    this.text.text.anchor.set(0.5, 0.5);

    this.text.text.x = RETRIGGER_MESSAGE_POSITION_X;
    this.text.text.y = RETRIGGER_MESSAGE_POSITION_Y;

    this.startRetrigger();
  }

  private startRetrigger(): void {
    this.addChild(this.text.getText());

    const animation = createZoomAnimation(
      this.text.text,
      RETRIGGER_MESSAGE_ZOOM_SCALE * this.text.text.scale.x,
      RETRIGGER_MESSAGE_ZOOM_DURATION,
      false,
      this.text.text.scale.x,
    );
    const delay = Tween.createDelayAnimation(RETRIGGER_MESSAGE_DELAY_DURATION);
    animation.appendAnimation(delay);

    const fadeOut = new Tween({
      object: this.text.text,
      target: 0,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      duration: RETRIGGER_MESSAGE_FADEOUT_DURATION,
    });
    animation.appendAnimation(fadeOut);

    animation.addOnComplete(() => {
      this.handleDestroy();
    });

    animation.start();
  }

  private handleDestroy(): void {
    SlotMachine.getInstance().gameView.removeChild(this);
    eventManager.emit(EventTypes.END_RETRIGGER_FEATURE);
    this.destroy({ children: true, texture: true, baseTexture: true });
  }
}

export default RetriggerMessage;
