import { EventTypes, PopupOpeningTypes } from '../../global.d';
import {
  setIsBuyFeaturePopupOpened,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import { calcPercentage, isBaseGameMode } from '../../utils';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class InfoBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private modeChange = false;

  constructor() {
    super('info');
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, () => {
      this.modeChange = false;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_MODE_CHANGE_FADE, () => {
      this.modeChange = true;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
  };

  private handleClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.INFO_POPUP);

      setIsOpenInfoPopup(!setIsOpenInfoPopup());
      setIsOpenHistoryPopup(false);
      setIsOpenAutoplayPopup(false);
      setIsOpenBetSettingsPopup(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  public override handleDisable(): void {
    this.setDisable(
      !isBaseGameMode(this.gameMode) ||
        this.modeChange ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        setIsBuyFeaturePopupOpened(),
    );
  }

  public override handlePosition(): void {
    this.btn.anchor.set(0, 1);
    let size = calcPercentage(this.applicationSize.width, 7.5);

    const gap = calcPercentage(this.applicationSize.width, 1.25);
    let x = gap;
    let y = this.applicationSize.height - gap;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      this.btn.anchor.set(0, 0);
      x = gap;
      y = gap;
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default InfoBtn;
