import { GameMode } from '../global.d';
import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'Game ID',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.symWl,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symA,
      combos: [
        { pattern: 'x5', multiplier: 1500 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 25 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symB,
      combos: [
        { pattern: 'x5', multiplier: 1200 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 25 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symC,
      combos: [
        { pattern: 'x5', multiplier: 1000 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 15 },
      ],
    },
    {
      slug: ResourceTypes.symD,
      combos: [
        { pattern: 'x5', multiplier: 750 },
        { pattern: 'x4', multiplier: 75 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symE,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 75 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symF,
      combos: [
        { pattern: 'x5', multiplier: 125 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symG,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symH,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symI,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symJ,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symK,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symSc,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 50 },
      ],
    },
  ],
};

export const reelSetsLayout = {
  [GameMode.BASE_GAME]: {
    id: '4693431f-933f-4943-9710-418d6e59ef0e',
    layout: [ 
      ['G,D,H,WL,I,SC,G,E,F,C,K,E,J,C,I,E,J,A,H,B,F,E,I,H,C,K,E,H,C,MS,MS,MS,MS,MS,C,I,A,H,C,K,E,H,C,E'],
      ['SC,F,WL,I,A,H,B,K,D,I,C,J,SC,I,B,G,D,I,WL,K,B,I,D,K,A,I,D,K,B,G,D,F,B,MS,MS,MS,MS,MS,B,I,E,K,D,I'],
      ['WL,J,H,I,SC,H,J,A,H,MS,MS,MS,F,A,G,H,A,J,G,C,H,G,B,H,F,D,K,J,A,G,J,E,H,J,MS,MS,MS,H,J,G'],
      ['A,H,WL,K,E,MS,MS,MS,G,C,F,E,G,I,C,J,D,K,E,I,SC,J,B,F,D,G,K,B,G,A,K,MS,MS,MS,G,E,F,K'],
      ['I,B,H,WL,G,B,J,MS,MS,MS,E,H,A,I,D,H,SC,K,B,F,C,E,J,A,F,C,D,J,E,F,D,I,MS,MS,MS,K,C'],
    ],
  },
  [GameMode.FREE_SPINS]: {
    id: '',
    layout: [
      ['G,D,H,WL,I,SC,G,E,MS,MS,MS,F,C,K,MS,MS,MS,E,J,C,I,E,MS,MS,MS,J,A,H,B,F,E,I,WL,H,C,K,MS,MS,MS,E,H,C,MS,MS,MS,C,I,A,H,C,K,MS,MS,MS,MS,E,H,C,E'],
      ['SC,F,WL,I,A,H,B,K,MS,MS,MS,D,I,C,J,SC,I,B,G,D,I,WL,K,B,I,D,MS,MS,MS,K,A,I,D,MS,MS,MS,K,B,G,MS,MS,MS,D,F,B,MS,MS,MS,B,I,E,MS,MS,MS,MS,K,D,I'],
      ['WL,J,H,I,SC,H,J,A,H,MS,MS,MS,F,A,G,MS,MS,MS,H,A,J,MS,MS,MS,G,C,H,MS,MS,MS,G,B,H,MS,MS,MS,F,D,K,J,A,G,WL,J,E,H,J,MS,MS,MS,H,J,G'],
      ['A,H,WL,K,E,MS,MS,MS,G,C,F,E,G,I,C,J,D,K,E,I,SC,J,B,F,D,G,MS,MS,MS,K,B,G,A,K,MS,MS,MS,G,E,F,K'],
      ['I,B,H,WL,G,B,J,MS,MS,MS,E,H,A,I,D,H,SC,K,B,F,C,E,MS,MS,MS,J,A,F,C,D,J,E,F,D,I,MS,MS,MS,K,C'],
    ]
  },
  [GameMode.BUY_FEATURE]: {
    id: '',
    layout: [
      ['I,SC,G'],
      ['J,SC,F'],
      ['G,WL,J,H,I,SC,H,J,A,H,MS,MS,MS,F,A,G,H,A,J,G,C,H,G,B,H,F,D,K,J,A,G,J,E,H,J,MS,MS,MS,H,J'],
      ['K,A,H,WL,K,E,MS,MS,MS,G,C,F,E,G,I,C,J,D,K,E,I,SC,J,B,F,D,G,K,B,G,A,K,MS,MS,MS,G,E,F'],
      ['H,SC,K'],
    ]
  }
}

export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  MS = 'MS',
  WL = 'WL',
  SC = 'SC',
}
