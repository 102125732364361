import type React from 'react';

const BurgerSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M33.5999 46.4C33.5999 42.8654 36.4652 40 39.9999 40H104C107.534 40 110.4 42.8654 110.4 46.4C110.4 49.9346 107.534 52.8 104 52.8H39.9999C36.4652 52.8 33.5999 49.9346 33.5999 46.4Z"
        fill="white"
      />
      <path
        d="M33.5999 72C33.5999 68.4654 36.4652 65.6 39.9999 65.6H104C107.534 65.6 110.4 68.4654 110.4 72C110.4 75.5346 107.534 78.4 104 78.4H39.9999C36.4652 78.4 33.5999 75.5346 33.5999 72Z"
        fill="white"
      />
      <path
        d="M39.9999 91.2C36.4652 91.2 33.5999 94.0654 33.5999 97.6C33.5999 101.135 36.4652 104 39.9999 104H104C107.534 104 110.4 101.135 110.4 97.6C110.4 94.0654 107.534 91.2 104 91.2H39.9999Z"
        fill="white"
      />
    </svg>
  );
};

export default BurgerSvg;
