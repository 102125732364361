import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { EventTypes } from '../../global.d';
import { configGql, setIsProcessToGame, setIsSlotBusy, setIsSpinInProgress, setSlotConfig } from '../../gql';
import type { IConfig } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, eventManager } from '../../slotMachine/config';
import { wrap } from '../../utils';
import AutoPlaySettingsPopup from '../AutoPlaySettings/AutoPlaySettingsPopup';
import BetSettingsPopup from '../BetSettings/BetSettingsPopup';
import BuyFeature from '../BuyFeature';
import EventListener from '../EventListener';
import HistoryPopup from '../History/HistoryPopup';
import InfoPopup from '../Info/InfoPopup';
import IntroScreen from '../IntroScreen/introScreen';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';

export interface IPixiViewParentNode extends Node, ParentNode {
  clientWidth: number;
  clientHeight: number;
}

export const application = new PIXI.Application({
  resolution: window.devicePixelRatio || 1,
  autoDensity: true,
  backgroundAlpha: 0,
  width: GAME_CONTAINER_WIDTH,
  height: GAME_CONTAINER_HEIGHT,
});

export const animator = new Animator(application);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.PIXI = PIXI;

const GameScreen: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isSoundOn } = clientData!;
  const processToGame = useReactiveVar(setIsProcessToGame);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);

  const resize = (application: PIXI.Application) => (): void => {
    const parent = application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    eventManager.emit(EventTypes.RESIZE, width, height);
  };

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    pixiContainerRef.current?.appendChild(application.view);
    IntroScreen.initIntroScreen(application);
    eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      SlotMachine.initSlotMachine(
        application,
        setSlotConfig(),
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
      resize(application)();
    });
    resize(application)();
    window.addEventListener(EventTypes.RESIZE, resize(application));
    return () => {
      window.removeEventListener(EventTypes.RESIZE, resize(application));
      AudioApi.off('all');
    };
  }, []);

  return (
    <>
      <div className={styles['canvas']!} ref={pixiContainerRef} />
      {processToGame && (
        <>
          <EventListener />
          <AutoPlaySettingsPopup />
          <InfoPopup />
          <BuyFeature />
          <HistoryPopup />
          <BetSettingsPopup />
          <Spin />
        </>
      )}
    </>
  );
};

export default GameScreen;
