import { makeVar } from '@apollo/client';

import AudioApi from '@phoenix7dev/audio-api';

import { SlotId } from '../config';
import { BonusStatus, GameMode, IBalance, IBonusNew, PopupOpeningTypes } from '../global.d';
import { IconType } from '../slotMachine/d';
import { getUserConfig } from '../utils';

import { IBet, ISlotConfig, IStressful } from './d';

const isDemoMode = new URLSearchParams(window.location.search).has('isDemo');

export const setSlotConfig = makeVar<ISlotConfig>({
  slotSettings: {
    betLines: { max: 0, min: 0 },
    cols: 5,
    rows: 3,
    globalCoinAmountMultiplier: 25,
    startingPositions: [0, 0, 0, 0, 0],
    buyFeaturePackages: [],
  },
  slotId: '',
  sessionId: '',
  clientSlotSettings: {
    autoplaySettings: {
      options: [25, 50, 75, 100, 500],
      conditions: {
        stopIfBalanceDecreasesBy: {
          enabled: true,
        },
        stopIfBalanceIncreasesBy: {
          enabled: true,
          multipliers: [
            1, 5, 10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000,
          ],
        },
        stopIfFeatureIsWon: {
          enabled: true,
        },
        stopIfSingleWinExceeds: {
          enabled: true,
          multipliers: [1, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300, 350, 400, 450, 500],
        },
        stopOnAnyWin: {
          enabled: true,
        },
      },
    },
    betCoinAmountSettings: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 20, 24, 28, 32, 40, 48, 60, 72, 80, 88, 100, 112, 120, 128, 140,
      152, 160, 168, 180, 192, 200, 240, 280, 320, 360, 400,
    ],
    coinValueSettings: [{ code: 'FUN', variants: [100] }],
    quickBetCoinAmountSettings: [1, 2, 4, 8, 20, 40, 80, 120, 200, 400],
  },
  lines: [
    [5, 6, 7, 8, 9], // 1
    [0, 1, 2, 3, 4], // 2
    [10, 11, 12, 13, 14], // 3
    [0, 6, 12, 8, 4], // 4
    [10, 6, 2, 8, 14], // 5
    [0, 6, 2, 8, 4], // 6
    [10, 6, 12, 8, 14], // 7
    [5, 1, 7, 3, 9], // 8
    [5, 11, 7, 13, 9], // 9
    [5, 1, 2, 3, 9], // 10
    [5, 11, 12, 13, 9], // 11
    [0, 6, 7, 8, 4], // 12
    [10, 6, 7, 8, 14], // 13
    [0, 11, 2, 13, 4], // 14
    [10, 1, 12, 3, 14], // 15
    [0, 11, 12, 13, 4], // 16
    [10, 1, 2, 3, 14], // 17
    [5, 6, 2, 8, 9], // 18
    [5, 6, 12, 8, 9], // 19
    [0, 1, 12, 3, 4], // 20
    [10, 11, 2, 13, 14], // 21
    [0, 1, 7, 3, 4], // 22
    [10, 11, 7, 13, 14], // 23
    [5, 1, 12, 3, 9], // 24
    [5, 11, 2, 13, 9], // 25
  ],
  reels: [
    {
      layout: [
        [
          'G',
          'D',
          'H',
          'WL',
          'I',
          'SC',
          'G',
          'E',
          'F',
          'C',
          'K',
          'E',
          'J',
          'C',
          'I',
          'E',
          'J',
          'A',
          'H',
          'B',
          'F',
          'E',
          'I',
          'H',
          'C',
          'K',
          'E',
          'H',
          'C',
          'MS',
          'MS',
          'MS',
          'MS',
          'MS',
          'C',
          'I',
          'A',
          'H',
          'C',
          'K',
          'E',
          'H',
          'C',
          'E',
          'G',
          'D',
          'H',
          'WL',
          'I',
          'SC',
          'G',
          'E',
          'F',
          'C',
          'K',
          'E',
          'J',
          'C',
          'I',
          'E',
          'J',
          'A',
          'H',
          'B',
          'F',
          'E',
          'I',
          'H',
          'C',
          'K',
          'E',
          'H',
          'C',
          'MS',
          'MS',
          'MS',
          'MS',
          'MS',
          'C',
          'I',
          'A',
          'H',
          'C',
          'K',
          'E',
          'H',
          'C',
          'E',
        ] as SlotId[],
        [
          'SC',
          'F',
          'WL',
          'I',
          'A',
          'H',
          'B',
          'K',
          'D',
          'I',
          'C',
          'J',
          'SC',
          'I',
          'B',
          'G',
          'D',
          'I',
          'WL',
          'K',
          'B',
          'I',
          'D',
          'K',
          'A',
          'I',
          'D',
          'K',
          'B',
          'G',
          'D',
          'F',
          'B',
          'MS',
          'MS',
          'MS',
          'MS',
          'MS',
          'B',
          'I',
          'E',
          'K',
          'D',
          'I',
          'SC',
          'F',
          'WL',
          'I',
          'A',
          'H',
          'B',
          'K',
          'D',
          'I',
          'C',
          'J',
          'SC',
          'I',
          'B',
          'G',
          'D',
          'I',
          'WL',
          'K',
          'B',
          'I',
          'D',
          'K',
          'A',
          'I',
          'D',
          'K',
          'B',
          'G',
          'D',
          'F',
          'B',
          'MS',
          'MS',
          'MS',
          'MS',
          'MS',
          'B',
          'I',
          'E',
          'K',
          'D',
          'I',
        ] as SlotId[],
        [
          'WL',
          'J',
          'H',
          'I',
          'SC',
          'H',
          'J',
          'A',
          'H',
          'MS',
          'MS',
          'MS',
          'F',
          'A',
          'G',
          'H',
          'A',
          'J',
          'G',
          'C',
          'H',
          'G',
          'B',
          'H',
          'F',
          'D',
          'K',
          'J',
          'A',
          'G',
          'J',
          'E',
          'H',
          'J',
          'MS',
          'MS',
          'MS',
          'H',
          'J',
          'G',
          'WL',
          'J',
          'H',
          'I',
          'SC',
          'H',
          'J',
          'A',
          'H',
          'MS',
          'MS',
          'MS',
          'F',
          'A',
          'G',
          'H',
          'A',
          'J',
          'G',
          'C',
          'H',
          'G',
          'B',
          'H',
          'F',
          'D',
          'K',
          'J',
          'A',
          'G',
          'J',
          'E',
          'H',
          'J',
          'MS',
          'MS',
          'MS',
          'H',
          'J',
          'G',
        ] as SlotId[],
        [
          'A',
          'H',
          'WL',
          'K',
          'E',
          'MS',
          'MS',
          'MS',
          'G',
          'C',
          'F',
          'E',
          'G',
          'I',
          'C',
          'J',
          'D',
          'K',
          'E',
          'I',
          'SC',
          'J',
          'B',
          'F',
          'D',
          'G',
          'K',
          'B',
          'G',
          'A',
          'K',
          'MS',
          'MS',
          'MS',
          'G',
          'E',
          'F',
          'K',
          'A',
          'H',
          'WL',
          'K',
          'E',
          'MS',
          'MS',
          'MS',
          'G',
          'C',
          'F',
          'E',
          'G',
          'I',
          'C',
          'J',
          'D',
          'K',
          'E',
          'I',
          'SC',
          'J',
          'B',
          'F',
          'D',
          'G',
          'K',
          'B',
          'G',
          'A',
          'K',
          'MS',
          'MS',
          'MS',
          'G',
          'E',
          'F',
          'K',
        ] as SlotId[],
        [
          'I',
          'B',
          'H',
          'WL',
          'G',
          'B',
          'J',
          'MS',
          'MS',
          'MS',
          'E',
          'H',
          'A',
          'I',
          'D',
          'H',
          'SC',
          'K',
          'B',
          'F',
          'C',
          'E',
          'J',
          'A',
          'F',
          'C',
          'D',
          'J',
          'E',
          'F',
          'D',
          'I',
          'MS',
          'MS',
          'MS',
          'K',
          'C',
          'I',
          'B',
          'H',
          'WL',
          'G',
          'B',
          'J',
          'MS',
          'MS',
          'MS',
          'E',
          'H',
          'A',
          'I',
          'D',
          'H',
          'SC',
          'K',
          'B',
          'F',
          'C',
          'E',
          'J',
          'A',
          'F',
          'C',
          'D',
          'J',
          'E',
          'F',
          'D',
          'I',
          'MS',
          'MS',
          'MS',
          'K',
          'C',
        ] as SlotId[],
      ],
      type: GameMode.BASE_GAME, //BASE_GAME
      additionalReelSets: [['WL', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'] as SlotId[]],
    },
    {
      layout: [
        [
          'G',
          'D',
          'H',
          'WL',
          'I',
          'SC',
          'G',
          'E',
          'MS',
          'MS',
          'MS',
          'F',
          'C',
          'K',
          'MS',
          'MS',
          'MS',
          'E',
          'J',
          'C',
          'I',
          'E',
          'MS',
          'MS',
          'MS',
          'J',
          'A',
          'H',
          'B',
          'F',
          'E',
          'I',
          'WL',
          'H',
          'C',
          'K',
          'MS',
          'MS',
          'MS',
          'E',
          'H',
          'C',
          'MS',
          'MS',
          'MS',
          'C',
          'I',
          'A',
          'H',
          'C',
          'K',
          'MS',
          'MS',
          'MS',
          'MS',
          'E',
          'H',
          'C',
          'E',
          'G',
          'D',
          'H',
          'WL',
          'I',
          'SC',
          'G',
          'E',
          'MS',
          'MS',
          'MS',
          'F',
          'C',
          'K',
          'MS',
          'MS',
          'MS',
          'E',
          'J',
          'C',
          'I',
          'E',
          'MS',
          'MS',
          'MS',
          'J',
          'A',
          'H',
          'B',
          'F',
          'E',
          'I',
          'WL',
          'H',
          'C',
          'K',
          'MS',
          'MS',
          'MS',
          'E',
          'H',
          'C',
          'MS',
          'MS',
          'MS',
          'C',
          'I',
          'A',
          'H',
          'C',
          'K',
          'MS',
          'MS',
          'MS',
          'MS',
          'E',
          'H',
          'C',
          'E',
        ] as SlotId[],
        [
          'SC',
          'F',
          'WL',
          'I',
          'A',
          'H',
          'B',
          'K',
          'MS',
          'MS',
          'MS',
          'D',
          'I',
          'C',
          'J',
          'SC',
          'I',
          'B',
          'G',
          'D',
          'I',
          'WL',
          'K',
          'B',
          'I',
          'D',
          'MS',
          'MS',
          'MS',
          'K',
          'A',
          'I',
          'D',
          'MS',
          'MS',
          'MS',
          'K',
          'B',
          'G',
          'MS',
          'MS',
          'MS',
          'D',
          'F',
          'B',
          'MS',
          'MS',
          'MS',
          'B',
          'I',
          'E',
          'MS',
          'MS',
          'MS',
          'MS',
          'K',
          'D',
          'I',
          'SC',
          'F',
          'WL',
          'I',
          'A',
          'H',
          'B',
          'K',
          'MS',
          'MS',
          'MS',
          'D',
          'I',
          'C',
          'J',
          'SC',
          'I',
          'B',
          'G',
          'D',
          'I',
          'WL',
          'K',
          'B',
          'I',
          'D',
          'MS',
          'MS',
          'MS',
          'K',
          'A',
          'I',
          'D',
          'MS',
          'MS',
          'MS',
          'K',
          'B',
          'G',
          'MS',
          'MS',
          'MS',
          'D',
          'F',
          'B',
          'MS',
          'MS',
          'MS',
          'B',
          'I',
          'E',
          'MS',
          'MS',
          'MS',
          'MS',
          'K',
          'D',
          'I',
        ] as SlotId[],
        [
          'WL',
          'J',
          'H',
          'I',
          'SC',
          'H',
          'J',
          'A',
          'H',
          'MS',
          'MS',
          'MS',
          'F',
          'A',
          'G',
          'MS',
          'MS',
          'MS',
          'H',
          'A',
          'J',
          'MS',
          'MS',
          'MS',
          'G',
          'C',
          'H',
          'MS',
          'MS',
          'MS',
          'G',
          'B',
          'H',
          'MS',
          'MS',
          'MS',
          'F',
          'D',
          'K',
          'J',
          'A',
          'G',
          'WL',
          'J',
          'E',
          'H',
          'J',
          'MS',
          'MS',
          'MS',
          'H',
          'J',
          'G',
          'WL',
          'J',
          'H',
          'I',
          'SC',
          'H',
          'J',
          'A',
          'H',
          'MS',
          'MS',
          'MS',
          'F',
          'A',
          'G',
          'MS',
          'MS',
          'MS',
          'H',
          'A',
          'J',
          'MS',
          'MS',
          'MS',
          'G',
          'C',
          'H',
          'MS',
          'MS',
          'MS',
          'G',
          'B',
          'H',
          'MS',
          'MS',
          'MS',
          'F',
          'D',
          'K',
          'J',
          'A',
          'G',
          'WL',
          'J',
          'E',
          'H',
          'J',
          'MS',
          'MS',
          'MS',
          'H',
          'J',
          'G',
        ] as SlotId[],
        [
          'A',
          'H',
          'WL',
          'K',
          'E',
          'MS',
          'MS',
          'MS',
          'G',
          'C',
          'F',
          'E',
          'G',
          'I',
          'C',
          'J',
          'D',
          'K',
          'E',
          'I',
          'SC',
          'J',
          'B',
          'F',
          'D',
          'G',
          'MS',
          'MS',
          'MS',
          'K',
          'B',
          'G',
          'A',
          'K',
          'MS',
          'MS',
          'MS',
          'G',
          'E',
          'F',
          'K',
          'A',
          'H',
          'WL',
          'K',
          'E',
          'MS',
          'MS',
          'MS',
          'G',
          'C',
          'F',
          'E',
          'G',
          'I',
          'C',
          'J',
          'D',
          'K',
          'E',
          'I',
          'SC',
          'J',
          'B',
          'F',
          'D',
          'G',
          'MS',
          'MS',
          'MS',
          'K',
          'B',
          'G',
          'A',
          'K',
          'MS',
          'MS',
          'MS',
          'G',
          'E',
          'F',
          'K',
        ] as SlotId[],
        [
          'I',
          'B',
          'H',
          'WL',
          'G',
          'B',
          'J',
          'MS',
          'MS',
          'MS',
          'E',
          'H',
          'A',
          'I',
          'D',
          'H',
          'SC',
          'K',
          'B',
          'F',
          'C',
          'E',
          'MS',
          'MS',
          'MS',
          'J',
          'A',
          'F',
          'C',
          'D',
          'J',
          'E',
          'F',
          'D',
          'I',
          'MS',
          'MS',
          'MS',
          'K',
          'C',
          'I',
          'B',
          'H',
          'WL',
          'G',
          'B',
          'J',
          'MS',
          'MS',
          'MS',
          'E',
          'H',
          'A',
          'I',
          'D',
          'H',
          'SC',
          'K',
          'B',
          'F',
          'C',
          'E',
          'MS',
          'MS',
          'MS',
          'J',
          'A',
          'F',
          'C',
          'D',
          'J',
          'E',
          'F',
          'D',
          'I',
          'MS',
          'MS',
          'MS',
          'K',
          'C',
        ] as SlotId[],
      ],
      type: GameMode.FREE_SPINS,
      additionalReelSets: [['WL', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'] as SlotId[]],
    },
    {
      layout: [
        ['I', 'SC', 'G', 'I', 'SC', 'G'] as SlotId[],
        ['J', 'SC', 'F', 'J', 'SC', 'F'] as SlotId[],
        [
          'G',
          'WL',
          'J',
          'H',
          'I',
          'SC',
          'H',
          'J',
          'A',
          'H',
          'MS',
          'MS',
          'MS',
          'F',
          'A',
          'G',
          'H',
          'A',
          'J',
          'G',
          'C',
          'H',
          'G',
          'B',
          'H',
          'F',
          'D',
          'K',
          'J',
          'A',
          'G',
          'J',
          'E',
          'H',
          'J',
          'MS',
          'MS',
          'MS',
          'H',
          'J',
          'G',
          'WL',
          'J',
          'H',
          'I',
          'SC',
          'H',
          'J',
          'A',
          'H',
          'MS',
          'MS',
          'MS',
          'F',
          'A',
          'G',
          'H',
          'A',
          'J',
          'G',
          'C',
          'H',
          'G',
          'B',
          'H',
          'F',
          'D',
          'K',
          'J',
          'A',
          'G',
          'J',
          'E',
          'H',
          'J',
          'MS',
          'MS',
          'MS',
          'H',
          'J',
        ] as SlotId[],
        [
          'K',
          'A',
          'H',
          'WL',
          'K',
          'E',
          'MS',
          'MS',
          'MS',
          'G',
          'C',
          'F',
          'E',
          'G',
          'I',
          'C',
          'J',
          'D',
          'K',
          'E',
          'I',
          'SC',
          'J',
          'B',
          'F',
          'D',
          'G',
          'K',
          'B',
          'G',
          'A',
          'K',
          'MS',
          'MS',
          'MS',
          'G',
          'E',
          'F',
          'K',
          'A',
          'H',
          'WL',
          'K',
          'E',
          'MS',
          'MS',
          'MS',
          'G',
          'C',
          'F',
          'E',
          'G',
          'I',
          'C',
          'J',
          'D',
          'K',
          'E',
          'I',
          'SC',
          'J',
          'B',
          'F',
          'D',
          'G',
          'K',
          'B',
          'G',
          'A',
          'K',
          'MS',
          'MS',
          'MS',
          'G',
          'E',
          'F',
        ] as SlotId[],
        ['H', 'SC', 'K', 'H', 'SC', 'K'] as SlotId[],
      ],
      type: GameMode.BUY_FEATURE,
      additionalReelSets: [['WL', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'] as SlotId[]],
    },
  ],
  icons: [
    {
      id: SlotId.WL,
      type: IconType.WILD,
    },
    {
      id: SlotId.A,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.B,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.C,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.D,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.E,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.F,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.G,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.H,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.I,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.J,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.K,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.MS,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.SC,
      type: IconType.SCATTER,
    },
  ],
});

export const setUserLastBetResult = makeVar<IBet>({
  id: '',
  slotId: '',
  playerId: '',
  wager: {
    bet: null,
    coinAmount: 0,
    coinValue: 0,
    createdAt: '',
    id: '',
    order: 0,
    playerId: '',
    slotId: '',
    state: '',
    wagerSettings: {
      gameMode: GameMode.BASE_GAME,
      packageId: '',
      rounds: 0,
      originalReelPositions: [],
      originalGameMode: GameMode.BASE_GAME,
    },
    wagerStorage: {
      roundsPlayed: 0,
      totalWinCoinAmount: 0,
      previousTotalWinCoinAmount: 0,
    },
  },
  status: '',
  outcomes: [],
  coinAmount: 1,
  effectiveCoinAmount: 1,
  coinValue: 1,
  createdAt: '',
  spinResult: [],
  betStorage: { estimatedWinCoinAmount: 0 },
});

export const setUserBalance = makeVar<IBalance>({
  amount: 0,
  currency: '',
});

export const setCurrentBonus = makeVar<IBonusNew>({
  packageId: '',
  gameMode: GameMode.BASE_GAME,
  rounds: 10,
  roundsPlayed: 0,
  state: BonusStatus.CLOSED,
  coinAmount: 0,
  coinValue: 0,
  originalReelPositions: [],
  isBuyFeature: false,
});

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setIsSoundOn = makeVar<boolean>(isDemoMode ? true : getUserConfig('isSoundOn', true));

export const setIsShowSoundToast = makeVar<boolean>(AudioApi.isRestricted);

export const setBrokenGame = makeVar<boolean>(false);

export const setBrokenBuyFeature = makeVar<boolean>(false);

export const setIsProcessToGame = makeVar<boolean>(false);

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setPrevReelsPosition = makeVar<number[]>([0, 0, 0, 0, 0]);

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(25);

export const setCurrentFreeSpinsTotalWin = makeVar<number>(0);

export const setCoinValue = makeVar<number>(1);

export const setCoinAmount = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(0);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.BASE_GAME);

export const setCurrency = makeVar<string>('FUN');

export const setReelSetId = makeVar<string>('');

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setGameHistory = makeVar<boolean[]>([false, false, false]);

export const setIsTurboSpin = makeVar<boolean>(isDemoMode ? false : getUserConfig('isTurboSpin', false));

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsBuyFeaturePopupOpened = makeVar<boolean>(false);
export const setIsMessageBannerOpened = makeVar<{
  opened: boolean;
  callback: (() => void) | undefined;
}>({
  opened: false,
  callback: () => {},
});
export const setIsOpenInfoPopup = makeVar<boolean>(false);
export const setIsOpenHistoryPopup = makeVar<boolean>(false);
export const setIsOpenBetSettingsPopup = makeVar<boolean>(false);
export const setIsOpenAutoplayPopup = makeVar<boolean>(false);
export const setIsPopupOpeningInProgress = makeVar<PopupOpeningTypes>(PopupOpeningTypes.NONE);
export const setIsDuringBigWinLoop = makeVar<boolean>(false);
export const setIsSoundLoading = makeVar<boolean>(false);
export const setPhoenixAnticipation = makeVar<boolean>(false);

export const setIsErrorMessage = makeVar<boolean>(false);
export const setIsTimeoutErrorMessage = makeVar<boolean>(false);
export const setIsBuyFeaturePurchased = makeVar<boolean>(false);
