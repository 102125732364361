import type React from 'react';

const SpinSvg = () => {
  return (
    <svg width="328" height="328" viewBox="0 0 328 328" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M164 312C245.738 312 312 245.738 312 164C312 82.2619 245.738 16 164 16C82.2619 16 16 82.2619 16 164C16 245.738 82.2619 312 164 312Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M82.6 164C82.6 119.042 119.042 82.6 164 82.6C208.958 82.6 245.4 119.042 245.4 164H226.9C222.327 164 219.716 169.221 222.46 172.88L255.76 217.28C257.98 220.24 262.42 220.24 264.64 217.28L297.94 172.88C300.684 169.221 298.073 164 293.5 164H275C275 102.694 225.306 53 164 53C102.694 53 53 102.694 53 164C53 225.306 102.694 275 164 275C172.174 275 178.8 268.374 178.8 260.2C178.8 252.026 172.174 245.4 164 245.4C119.042 245.4 82.6 208.958 82.6 164Z"
        fill="white"
        className="svg-color"
      />
    </svg>
  );
};

export default SpinSvg;
