import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setBrokenGame } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/container';
import { GAME_CONTAINER_TITLE_LOGO_Z_INDEX, eventManager } from '../config';

class GameTitle extends ViewContainer {
  private logo: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelLogo));

  constructor() {
    super();
    this.logo.width = 550;
    this.logo.height = 130;
    this.logo.anchor.set(0.5);
    this.logo.x = 0;
    this.logo.y = -370;
    this.visible = !setBrokenGame();
    this.addChild(this.logo);

    this.trackZIndex();
  }

  public trackZIndex(): void {
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.zIndex = GAME_CONTAINER_TITLE_LOGO_Z_INDEX;
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.zIndex = 0;
    });
  }
}

export default GameTitle;
