import type React from 'react';

const SoundSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M87.3007 35.8075C88.8722 34.5503 91.2 35.6691 91.2 37.6815V104.495C91.2 106.507 88.8722 107.626 87.3007 106.369L59.8574 84.414C59.4318 84.0735 58.9031 83.888 58.3581 83.888H42.4C41.0745 83.888 40 82.8135 40 81.488V60.688C40 59.3626 41.0745 58.288 42.4 58.288H58.3581C58.9031 58.288 59.4318 58.1026 59.8574 57.7621L87.3007 35.8075Z"
        fill="white"
      />
    </svg>
  );
};

export default SoundSvg;
