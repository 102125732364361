import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, Popup } from '@phoenix7dev/shared-components/';

import { ISongs } from '../../config';
import { PopupOpeningTypes } from '../../global.d';
import { setIsOpenHistoryPopup, setIsPopupOpeningInProgress } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';

import HistoryComponent from './HistoryComponent';

const HistoryPopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { data: configData } = useQuery<IConfig>(configGql);
  const { t } = useTranslation();
  const { isOpenHistoryPopup } = configData!;

  useEffect(() => {
    if (isOpenHistoryPopup) {
      AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
    }
    setShowPopup(isOpenHistoryPopup as boolean);
  }, [isOpenHistoryPopup]);

  useEffect(() => {
    if (showPopup && configData?.isSpinInProgress) {
      setShowPopup(false);
    }
  }, [configData?.isSpinInProgress, showPopup]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsPopupOpeningInProgress() &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.HISTORY_POPUP &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP &&
      !directClickToCloseIcon
    )
      return;

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setIsOpenHistoryPopup(false);
  };

  return (
    <Popup id="popup" className="popup" showNow={showPopup} setShowNow={() => handleClose()}>
      <div className="popup__title">{t('gameHistory.title')}</div>
      <Button intent="close" className="popup__close" onClick={handleClose} />
      <HistoryComponent />
    </Popup>
  );
};

export default HistoryPopup;
