import type React from 'react';

const AutoplayOffSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M57.479 42.9414C61.8488 40.7497 66.8009 39.504 72.0321 39.504C89.9714 39.504 104.528 54.0607 104.528 72H97.5047C95.5269 72 94.398 74.258 95.5849 75.8402L108.512 93.0728C109.472 94.3525 111.392 94.3525 112.352 93.0728L125.279 75.8402C126.466 74.258 125.337 72 123.359 72H116.336C116.336 47.5393 96.4928 27.696 72.0321 27.696C64.9113 27.696 58.1514 29.3943 52.1852 32.3866C49.2706 33.8484 48.0928 37.3962 49.5546 40.3109C51.0165 43.2255 54.5643 44.4033 57.479 42.9414Z"
        fill="white"
        className="svg-color"
      />
      <path
        d="M27.7281 72H21.4407C19.4629 72 18.334 69.7419 19.5209 68.1598L32.4482 50.9272C33.4082 49.6475 35.3279 49.6475 36.2879 50.9272L49.2153 68.1598C50.4021 69.7419 49.2732 72 47.2954 72H39.5361C39.5361 89.9393 54.0928 104.496 72.0321 104.496C77.4325 104.496 82.5192 103.181 86.9798 100.87C89.8748 99.3698 93.4379 100.501 94.9381 103.396C96.4383 106.291 95.3075 109.854 92.4124 111.354C86.3129 114.515 79.3676 116.304 72.0321 116.304C47.5714 116.304 27.7281 96.4607 27.7281 72Z"
        fill="white"
        className="svg-color"
      />
      <path
        d="M58.2858 60C58.2858 58.6745 59.3603 57.6 60.6858 57.6H84.6858C86.0113 57.6 87.0858 58.6745 87.0858 60V84C87.0858 85.3255 86.0113 86.4 84.6858 86.4H60.6858C59.3603 86.4 58.2858 85.3255 58.2858 84V60Z"
        fill="white"
        className="svg-color"
      />
    </svg>
  );
};

export default AutoplayOffSvg;
