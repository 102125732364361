import type React from 'react';

const BetSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M41.1999 44.1732C40.4408 44.5403 40 45.3159 40 46.1592V56.7317C40 57.2927 40.2013 57.8333 40.6466 58.1746C42.6972 59.7463 50.2556 64 72 64C93.7444 64 101.303 59.7463 103.353 58.1746C103.799 57.8333 104 57.2927 104 56.7317V46.1592C104 45.3159 103.559 44.5403 102.8 44.1732C99.4684 42.5617 89.1989 38.4 72 38.4C54.8011 38.4 44.5316 42.5617 41.1999 44.1732Z"
        fill="white"
        className="svg-color"
      />
      <path
        d="M40 69.8377C40 67.9725 42.1062 66.6917 43.8428 67.3725C48.6745 69.2666 58.0617 72 72 72C85.9383 72 95.3255 69.2666 100.157 67.3725C101.894 66.6917 104 67.9725 104 69.8377V77.5317C104 78.0927 103.799 78.6333 103.353 78.9746C101.303 80.5463 93.7444 84.8 72 84.8C50.2556 84.8 42.6972 80.5463 40.6466 78.9746C40.2013 78.6333 40 78.0927 40 77.5317V69.8377Z"
        fill="white"
        className="svg-color"
      />
      <path
        d="M40 90.6377C40 88.7725 42.1062 87.4917 43.8428 88.1725C48.6745 90.0666 58.0617 92.8 72 92.8C85.9383 92.8 95.3255 90.0666 100.157 88.1725C101.894 87.4917 104 88.7725 104 90.6377V98.3317C104 98.8927 103.799 99.4333 103.353 99.7746C101.303 101.346 93.7444 105.6 72 105.6C50.2556 105.6 42.6972 101.346 40.6466 99.7746C40.2013 99.4333 40 98.8927 40 98.3317V90.6377Z"
        fill="white"
        className="svg-color"
      />
    </svg>
  );
};

export default BetSvg;
