import { ISkeletonData, Spine } from 'pixi-spine';

import Animation from './animation';
import { IAnimation } from './d';

class SpineAnimation extends Animation {
  public spine: Spine;

  constructor(options: IAnimation, skeletonData: ISkeletonData) {
    super(options);
    this.spine = new Spine(skeletonData);
    this.spine.state.addListener({
      complete: this.onComplete.bind(this),
    });
  }

  public setAnimation(animationName: string, loop: boolean): void {
    this.spine.state.setAnimation(0, animationName, loop);
  }

  public start(): void {
    super.start();
  }
}
export default SpineAnimation;
