import { gql } from '@apollo/client';

export const OUTCOME = gql`
  fragment outcome on Outcome {
    id
    selectionState
    previousOutcomeId
    previousOutcome {
      id
    }
    nextOutcome {
      id
    }
    predicament {
      ... on AfricanAnimalsPredicament {
        reelPositions
        mysteryPositions
        mysterySymbol
      }
    }
    stateSnapshot {
      ... on AfricanAnimalsOutcomeSnapshot {
        fieldState
        winCombinations {
          attribution
          lineNumber
          rewards {
            type
            value
            multiplier
            entityId
          }
        }
        hasRewardedFreeSpins
      }
    }
    rewards {
      id
    }
    createdAt
    completedAt
  }
`;

export const BET = gql`
  fragment bet on Bet {
    id
    slotId
    playerId
    wager {
      ...wager
    }
    status
    betStorage {
      ... on CommonBetStorage {
        estimatedWinCoinAmount
      }
    }
    outcomes {
      id
      selectionState
      previousOutcomeId
      previousOutcome {
        id
      }
      nextOutcome {
        id
      }
      predicament {
        ... on AfricanAnimalsPredicament {
          reelPositions
          mysteryPositions
          mysterySymbol
        }
      }
      stateSnapshot {
        ... on AfricanAnimalsOutcomeSnapshot {
          fieldState
          winCombinations {
            attribution
            lineNumber
            rewards {
              type
              value
              multiplier
              entityId
            }
          }
          hasRewardedFreeSpins
        }
      }
      rewards {
        id
        type
        outcome {
          ...outcome
        }
        value
        multiplier
        entityId
      }
      createdAt
      completedAt
    }
    coinAmount
    effectiveCoinAmount
    coinValue
    createdAt
  }
`;

export const WAGER_SETTINGS = gql`
  fragment wagerSettings on WagerSettings {
    gameMode
    rounds
    packageId
    previousWagerStorage
    previousWagerSettings
  }
`;

export const WAGER_STORAGE = gql`
  fragment wagerStorage on WagerStorage {
    roundsPlayed
    totalWinCoinAmount
    previousTotalWinCoinAmount
    paidWinCoinAmount
  }
`;

export const WAGER = gql`
  fragment wager on Wager {
    id
    coinAmount
    coinValue
    wagerSettings {
      ... on CommonWagerSettings {
        gameMode
        rounds
        packageId
        originalReelPositions
        originalGameMode
      }
    }
    wagerStorage {
      ... on CommonWagerStorage {
        roundsPlayed
        totalWinCoinAmount
        previousTotalWinCoinAmount
        paidWinCoinAmount
      }
    }
    state
    order
    createdAt
  }
`;

export const USER_BALANCE = gql`
  fragment userBalance on Balance {
    amount
    currency
  }
`;
