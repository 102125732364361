import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import { Variables } from '../../config/variables';
import { EventTypes, FreeSpinsTitleProps } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/container';
import { eventManager } from '../config';

import { FREE_SPINS_TITLE_POSITION_Y } from './config';

const freeSpinsLeftTextStyle = {
  default: {
    align: 'center',
    fill: '#FFAA00',
    fillGradientStops: [0.6],
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 90,
    fontWeight: '700',
    lineJoin: 'round',
    miterLimit: 0,
    stroke: '#460211',
    strokeThickness: 20,
    whiteSpace: 'normal',
  },
  color: {
    fill: '#FFFF54',
  },
};

class FreeSpinsTitle extends ViewContainer {
  private background: PIXI.Sprite;

  private spins: TextField;

  private roundsPlayed: string;

  private rounds: string;

  constructor(props: FreeSpinsTitleProps) {
    super();

    this.y = FREE_SPINS_TITLE_POSITION_Y;
    this.background = this.initBackground();
    this.roundsPlayed = props.currentSpin;
    this.rounds = props.spins;
    this.spins = this.initSpins(this.rounds, this.roundsPlayed);

    this.addChild(this.background, this.spins.getText());
    this.y = -350;
    this.x = 0;

    eventManager.addListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, this.updateSpins.bind(this));
  }

  private initSpins(spins?: string, currentSpin?: string): TextField {
    const text = new TextField(
      `${i18n.t('freeSpins.title')} <color>${currentSpin}/${spins}</color>`,
      400,
      70,
      {},
      true,
      freeSpinsLeftTextStyle,
    );
    text.text.anchor.set(0.5);
    return text;
  }

  private updateSpins(current?: number, total?: number): void {
    this.rounds = total ? `${total}` : this.rounds;
    this.roundsPlayed = current ? `${current}` : `${this.roundsPlayed}`;
    this.spins.setText(`${i18n.t('freeSpins.title')} <color>${this.roundsPlayed}/${this.rounds}</color>`);
  }

  private initBackground(): PIXI.Sprite {
    const background = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.slotFreeSpinCounterBg));
    background.anchor.set(0.5);
    background.scale.set(0.66);
    return background;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE);
    this.destroy({ children: true, texture: true, baseTexture: true });
  }
}

export default FreeSpinsTitle;
