import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsBuyFeaturePurchased } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { eventManager } from '../config';

import { buyFeatureTitleStyle, totalCostTextAmountStyle, totalCostTextStyle } from './textStyles';

class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private coinAmount: number;

  private titleText: TextField;

  private totalCostText: TextField;

  private totalCostValue: TextField;

  private okBtn: PIXI.Sprite;

  private cancelBtn: PIXI.Sprite;

  constructor() {
    super();
    this.visible = false;
    this.coinAmount = 1;
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.interactive = true;
    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => this.handleClose());
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, (totalCost: string, coinAmount: number) => {
      this.handleOpen(totalCost, coinAmount);
    });
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.handleClose();
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText.getText());
    this.addChild(this.totalCostText.getText());
    this.addChild(this.totalCostValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));

    popupBg.anchor.set(0.5);

    return popupBg;
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('freeSpins.confirmBanner.title'),
      400,
      100,
      buyFeatureTitleStyle as Partial<PIXI.ITextStyle>,
    );
    title.text.name = 'title';
    title.text.y = -110;
    title.text.x = 0;
    title.text.anchor.set(0.5);

    return title;
  }

  private initTotalCostText(): TextField {
    const totalCostText = new TextField(
      i18n.t<string>('freeSpins.confirmBanner.cost'),
      400,
      100,
      totalCostTextStyle as Partial<PIXI.ITextStyle>,
    );
    totalCostText.text.name = 'totalCostText';
    totalCostText.text.y = 30;
    totalCostText.text.x = 0;
    totalCostText.text.anchor.set(0.5);

    return totalCostText;
  }

  private initTotalCostValue(): TextField {
    const betValue = new TextField('0', 300, 100, totalCostTextAmountStyle as Partial<PIXI.ITextStyle>);
    betValue.text.name = 'totalCostValue';
    betValue.text.y = 50;
    betValue.text.x = 0;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initCancelBtn(): PIXI.Sprite {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    cancelBtn.name = 'cancelBtn';
    cancelBtn.y = 155;
    cancelBtn.x = -42;
    cancelBtn.anchor.set(1, 0.5);
    return cancelBtn;
  }

  private onCancel() {
    this.handleClose();
    AudioApi.play({ type: ISongs.SFX_UI_Close });
  }

  private initOkBtn(): PIXI.Sprite {
    const confirmBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleClickOk.bind(this),
      onTouchStart: this.handleClickOk.bind(this),
    });
    confirmBtn.name = 'confirmBtn';
    confirmBtn.y = 155;
    confirmBtn.x = 42;
    confirmBtn.anchor.set(0, 0.5);
    return confirmBtn;
  }

  private handleClickOk = (): void => {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    AudioApi.play({ type: ISongs.SFX_BuyFeature });
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, this.coinAmount);
    setIsBuyFeaturePurchased(true);
  };

  public handleOpen(totalCost: string, coinAmount: number): void {
    this.visible = true;
    this.coinAmount = coinAmount;
    this.totalCostValue.text.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public handleClose(): void {
    this.visible = false;
  }
}

export default BuyFeaturePopupConfirm;
