export enum ResourceTypes {
  messageBanner = 'messageBanner',
  reelLogo = 'reelLogo',
  reelsLight1 = 'reelsLight1',
  reelsLight1Free = 'reelsLight1Free',
  reelsLight2 = 'reelsLight2',
  reelsLight2Free = 'reelsLight2Free',
  reelsLight3 = 'reelsLight3',
  reelsLight3Free = 'reelsLight3Free',
  reelsLight4 = 'reelsLight4',
  reelsLight4Free = 'reelsLight4Free',
  reelsLight5 = 'reelsLight5',
  reelsLight5Free = 'reelsLight5Free',
  frame = 'frame',
  largeLine = 'largeLine',
  logo = 'logo',
  mediumLine = 'mediumLine',
  shortLine = 'shortLine',
  blurSymA = 'blurSymA',
  blurSymB = 'blurSymB',
  blurSymC = 'blurSymC',
  blurSymD = 'blurSymD',
  blurSymE = 'blurSymE',
  blurSymF = 'blurSymF',
  blurSymG = 'blurSymG',
  blurSymH = 'blurSymH',
  blurSymI = 'blurSymI',
  blurSymJ = 'blurSymJ',
  blurSymK = 'blurSymK',
  symA = 'symA',
  symB = 'symB',
  symC = 'symC',
  symD = 'symD',
  symE = 'symE',
  symF = 'symF',
  symG = 'symG',
  symH = 'symH',
  symI = 'symI',
  symJ = 'symJ',
  symK = 'symK',
  symMs1 = 'symMs1',
  symMs2 = 'symMs2',
  symSc = 'symSc',
  symWl = 'symWl',
  backdrop = 'backdrop',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnDisabled = 'buyFeatureCancelBtnDisabled',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisabled = 'buyFeatureOkBtnDisabled',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopup = 'buyFeaturePopup',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  introBg = 'introBg',
  introLight = 'introLight',
  introLogo = 'introLogo',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  popupBg = 'popupBg',
  slotBg = 'slotBg',
  slotFreeSpinBg = 'slotFreeSpinBg',
  slotFreeSpinCounterBg = 'slotFreeSpinCounterBg',
}
