import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ELoaderStages, ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';

import { SlotId, config } from '../config';
import { EventTypes } from '../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setGameMode,
  setSlotConfig,
  setStressful,
} from '../gql/cache';
import { eventManager } from '../slotMachine/config';

import { IPixiAssets } from './d';
import { isFreeSpinMode } from './helper';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
    PIXI.Loader.shared.onError.once(() => {
      return reject();
    });
  });
};

export const loadPixiAssets = (assets: IPixiAssets[], baseUrl: string): Promise<void> => {
  PIXI.Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    assets.forEach((asset) => PIXI.Loader.shared.add(asset.name, asset.src));
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (failed) {
        console.error(failed);
      }
    }

    return success ? resolve() : reject();
  });
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const getCssVariable = (cssVar: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (width < height) {
    return height * (parseInt(getCssVariable('--bottom-height-percent-portrait'), 10) / 100);
  }
  return height * (parseInt(getCssVariable('--bottom-height-percent-landscape'), 10) / 100);
};

export const getFromMappedSymbol = <T>(map: Record<SlotId, { default: T; freespin?: T }>, id: SlotId): T =>
  isFreeSpinMode(setGameMode()) ? map[id].freespin ?? map[id].default : map[id].default;

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};
export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  switch (stage?.name) {
    case ELoaderStages.AUTH:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t<string>(['errors.CLIENT.INVALID_CLIENT_TOKEN', 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
      break;
    default:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t<string>([
            errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN',
          ]) as string) || (error as unknown as string),
      });
  }
};

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i];

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0;
};

export const updateCoinValueAfterBonuses = (): void => {
  // updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
  const coinValue = setSlotConfig().clientSlotSettings.coinValueSettings.find((elem) => elem.code === setCurrency())
    ?.variants[0];
  const coinAmount = findSubstituteCoinAmount(
    setCoinAmount(),
    setSlotConfig().clientSlotSettings.betCoinAmountSettings,
  );
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().slotSettings.globalCoinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

export const queryParams = new URLSearchParams(window.location.search);
