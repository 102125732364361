import _ from 'lodash';

import { SlotId } from '../config';
import { BonusStatus, EventTypes, GameMode } from '../global.d';
import {
  setCurrentBonus,
  setIsBuyFeaturePurchased,
  setIsErrorMessage,
  setPrevReelsPosition,
  setUserLastBetResult,
} from '../gql';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  REELS_AMOUNT,
  eventManager,
} from '../slotMachine/config';
import { Icon } from '../slotMachine/d';

export const getAnticipationReelId = (spinResult: Icon[]): number => {
  if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
  let minReelId = REELS_AMOUNT;
  _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
    const count = ANTICIPATION_SYMBOLS_AMOUNT[i];
    let currentCount = 0;
    for (let j = 0; j < REELS_AMOUNT; j++) {
      // eslint-disable-next-line no-plusplus
      if (spinResult[j].id === symbolId) currentCount++;
      // eslint-disable-next-line no-plusplus
      if (spinResult[j + REELS_AMOUNT].id === symbolId) currentCount++;
      // eslint-disable-next-line no-plusplus
      if (spinResult[j + REELS_AMOUNT * 2].id === symbolId) currentCount++;

      if (currentCount >= count) minReelId = Math.min(minReelId, j);
    }
  });
  return minReelId;
};

export const getScatterCount = (spinResult: Icon[]): number[] => {
  let count = 0;
  return _(spinResult)
    .chunk(REELS_AMOUNT)
    .unzip()
    .map((col) => {
      if (col.some((icon) => icon.id === SlotId.SC)) {
        count += 1;
        return count;
      }
      return 0;
    })
    .value();
};

export const fallBackReelPosition = (): void => {
  if (!setIsBuyFeaturePurchased()) {
    setIsErrorMessage(true);
    let startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().outcomes[0].predicament.reelPositions
      : setPrevReelsPosition();
    if (
      setCurrentBonus().gameMode !== GameMode.BASE_GAME &&
      setUserLastBetResult().id &&
      setUserLastBetResult().wager.wagerSettings.gameMode === GameMode.FREE_SPINS &&
      setUserLastBetResult().wager.state === BonusStatus.CLOSED
    ) {
      startPosition = setCurrentBonus().originalReelPositions || startPosition;
    }
    eventManager.emit(
      EventTypes.SETUP_REEL_POSITIONS,
      startPosition,
      setUserLastBetResult().spinResult?.length ? getScatterCount(setUserLastBetResult().spinResult!) : [],
      setUserLastBetResult().spinResult?.length ? getAnticipationReelId(setUserLastBetResult().spinResult!) : 5,
      true,
    );
    eventManager.emit(EventTypes.FORCE_STOP_REELS);
    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
  } else {
    eventManager.emit(EventTypes.FORCE_CLOSE_BUYFEATURE);
  }
};
