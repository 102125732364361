import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, MAPPED_SYMBOLS_ANIMATIONS, SlotId } from '../../config';
import { GameMode } from '../../global.d';
import { getFromMappedSymbol } from '../../utils';
import Animation from '../animations/animation';
import AnimationGroup from '../animations/animationGroup';
import SpriteAnimation from '../animations/sprite';
import { ViewContainer } from '../components/container';
import {
  APPLICATION_FPS,
  MYSTERY_SPECIAL_REVEAL_ANIMATION_COEFFICIENT,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_HEIGHT,
  SPRITE_ANIMATION_FPS,
  TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT,
} from '../config';
import { Icon } from '../d';

class MysteryRevealContainer extends ViewContainer {
  constructor() {
    super();
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  public createMystery(spinResult: Icon[], _mode: GameMode): AnimationGroup {
    const isSpecial = this.isSpecialAnimation(spinResult);
    const revealSprite = getFromMappedSymbol(MAPPED_SYMBOLS_ANIMATIONS, SlotId.MS);

    const animationGroup = new AnimationGroup();
    _.forEach(spinResult, (icon, index) => {
      if (icon.id !== SlotId.MS) return;
      const animation = this.createSlotSpriteAnimation(
        _.get(PIXI.Loader.shared.resources, revealSprite).spritesheet!,
        index,
        false,
        isSpecial,
      );
      animationGroup.addAnimation(animation);
    });

    if (animationGroup.animations.length > 0) {
      const type = isSpecial ? ISongs.Mystery_SP : ISongs.Mystery_Nom;
      animationGroup.addOnStart(() => {
        AudioApi.play({ type, stopPrev: true });
      });
      animationGroup.addOnSkip(() => {
        AudioApi.stop({ type });
      });
    }

    return animationGroup;
  }

  private createSlotSpriteAnimation(
    sheet: PIXI.Spritesheet,
    id: number,
    isTurboSpin: boolean | undefined,
    isSpecial: boolean,
  ): Animation {
    const animatedSprite = new SpriteAnimation({}, Object.values(sheet?.textures));
    animatedSprite.spriteAnimation.scale.set(1.47);
    animatedSprite.spriteAnimation.animationSpeed =
      (isTurboSpin ? SPRITE_ANIMATION_FPS * TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT : SPRITE_ANIMATION_FPS) /
      APPLICATION_FPS;

    animatedSprite.spriteAnimation.animationSpeed *= isSpecial ? MYSTERY_SPECIAL_REVEAL_ANIMATION_COEFFICIENT : 1;
    animatedSprite.spriteAnimation.x = REEL_WIDTH * (id % 5) + REEL_WIDTH / 2;
    animatedSprite.spriteAnimation.y = SLOT_HEIGHT * Math.floor(id / 5) + SLOT_HEIGHT / 2;
    animatedSprite.addOnStart(() => {
      this.addChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnSkip(() => {
      this.removeChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnComplete(() => {
      this.removeChild(animatedSprite.spriteAnimation);
    });
    return animatedSprite;
  }

  private isSpecialAnimation(spinResult: Icon[]): boolean {
    const mysteryCounts = _.chunk(spinResult, REELS_AMOUNT).map(
      (icons) => _.countBy(icons, (icon) => icon.id === SlotId.MS).true,
    );

    const hasWild = spinResult.some((icon) => icon.id === SlotId.WL);

    if (mysteryCounts[1] >= 4) return true;
    if (mysteryCounts[1] >= 3 && hasWild) return true;
    if (mysteryCounts[0] === 5) return true;
    if (mysteryCounts[2] === 5) return true;
    if (_.sum(mysteryCounts) >= 10) return true;

    return false;
  }
}

export default MysteryRevealContainer;
