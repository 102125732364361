import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import { LOADER_MAPPED_SYMBOLS, LOADER_TEXTURES, audioSprite, audioSpriteVolume } from '../../config';
import { BonusStatus, EventTypes, GameMode, IAuthInput, IBalance, IWager } from '../../global.d';
import {
  authGql,
  client,
  configGql,
  getBalanceGql,
  getGameSettingsGql,
  getProgressGql,
  getRestoreStateGql,
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsBuyFeaturePopupOpened,
  setIsSoundOn,
  setIsTurboSpin,
  setProgress,
  setReelSetId,
  setSlotConfig,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
  slotHistoryGql,
} from '../../gql';
import type { IConfig, ISlotHistoryData } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import { IGameSettings } from '../../slotMachine/d';
import { findSubstituteCoinAmount, loadErrorHandler, loadPixiAssets, parseQuery } from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent] = useState(true);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getUserBalance = async () => {
      const {
        data: { balance },
      } = await client.query<{ balance: IBalance }>({
        query: getBalanceGql,
        fetchPolicy: 'network-only',
      });
      setUserBalance(balance);
      setCurrency(balance.currency);
    };

    const getLastBet = async () => {
      const {
        data: { betHistory },
      } = await client.query<{ betHistory: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { slotId: setSlotConfig().slotId, limit: 10 },
        },
        fetchPolicy: 'network-only',
      });
      if (betHistory.bets[0]) {
        setUserLastBetResult(betHistory.bets[0]);
      }
    };

    const getGameSettingsData = async () => {
      const { slotId, clientId } = parseQuery<Omit<IAuthInput, 'lng'>>();
      const {
        data: { gameSettings },
      } = await client.query<{ gameSettings: IGameSettings }>({
        query: getGameSettingsGql,
        variables: { input: { slotId, clientId } },
        fetchPolicy: 'network-only',
      });
      setSlotConfig({
        ...setSlotConfig(),
        clientSlotSettings: gameSettings.clientSlotSettings,
        slotSettings: gameSettings.slotSettings,
        slotId: gameSettings.slotId,
      });
      setGameMode(GameMode.BASE_GAME);
      setReelSetId(GameMode.BASE_GAME);

      let coinValue;
      let coinAmount;
      if (setBrokenGame() || setBrokenBuyFeature()) {
        const currentBonus = setCurrentBonus();
        coinValue = currentBonus.coinValue;
        coinAmount = currentBonus.coinAmount;
      } else {
        const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
        coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, gameSettings.clientSlotSettings.betCoinAmountSettings);
        coinValue = gameSettings.clientSlotSettings.coinValueSettings.find((elem) => elem.code === setCurrency())
          ?.variants[0];
      }

      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().betStorage.estimatedWinCoinAmount);
      setBetAmount(coinAmount * gameSettings.slotSettings.globalCoinAmountMultiplier);
    };

    const checkBrokenGame = async () => {
      const { data: restoreStateData } = await client.query<{ restoreState: { wagers: IWager[] } }>({
        query: getRestoreStateGql,
        variables: {
          input: { slotId: setSlotConfig().slotId },
        },
        fetchPolicy: 'network-only',
      });

      if (restoreStateData.restoreState.wagers.length > 0) {
        const wager = restoreStateData.restoreState.wagers[0];
        if (wager.wagerSettings.gameMode === GameMode.BUY_FEATURE) {
          setBrokenBuyFeature(true);
          setIsBuyFeaturePopupOpened(true);
          setCurrentBonus({
            packageId: wager.wagerSettings.packageId,
            gameMode: wager.wagerSettings.gameMode,
            rounds: wager.wagerSettings.rounds,
            roundsPlayed: wager.wagerStorage.roundsPlayed,
            state: wager.state as BonusStatus,
            coinAmount: wager.coinAmount,
            coinValue: wager.coinValue,
            originalReelPositions: wager.wagerSettings.originalReelPositions,
            isBuyFeature: true,
            mysterySymbol: wager.wagerSettings.mysterySymbol,
          });
        } else {
          setBrokenGame(true);
          setCurrentFreeSpinsTotalWin(
            wager.wagerStorage.previousTotalWinCoinAmount + wager.wagerStorage.totalWinCoinAmount,
          );
          setCurrentBonus({
            packageId: wager.wagerSettings.packageId,
            gameMode: wager.wagerSettings.gameMode,
            rounds: wager.wagerSettings.rounds,
            roundsPlayed: wager.wagerStorage.roundsPlayed,
            state: wager.state as BonusStatus,
            coinAmount: wager.coinAmount,
            coinValue: wager.coinValue,
            originalReelPositions: wager.wagerSettings.originalReelPositions,
            isBuyFeature: wager.wagerSettings.originalGameMode === GameMode.BUY_FEATURE,
            mysterySymbol: wager.wagerSettings.mysterySymbol,
          });
        }
      }
    };

    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        const { token, clientId } = parseQuery<{ token: string; clientId: string }>();
        const { data } = await getAuth({ variables: { input: { connectToken: token, clientId } } });

        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data?.auth.sessionId as string);
        rebuildStorageCache<IConfig>('config', {
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(30, ELoaderStages.BONUS_GAME, async (stage) => {
        await getUserBalance();
        await getLastBet();
        await checkBrokenGame();
        await getGameSettingsData();
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets([...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES], process.env.PUBLIC_URL);
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.IMAGES, async (stage) => {
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (_stage) => {
        await AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          restricted: false,
          isSoundEnabled: isSoundOn,
          onSuspended: () => {},
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
        }).then(() => {
          eventManager.emit(EventTypes.SOUND_INITIALIZED);
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: true,
          });
        });
      })
      .load();
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles['loadScreenWrapper']}>
      <div className={styles['logo']}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles['companyLogo']}
        />
        <div className={styles['companyLogo_loaded']} style={{ height: `${progress?.status}%` }}>
          <img draggable="false" alt="logoLoaded" src={Resources.getSource(ResourceTypes.logo)} />
        </div>
      </div>
    </div>
  );
};

export default LoadScreen;
