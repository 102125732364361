import { gql } from '@apollo/client';

import { BET, OUTCOME, USER_BALANCE, WAGER } from './fragments';

export const getRestoreStateGql = gql`
  ${BET}
  ${WAGER}
  ${OUTCOME}
  query getRestoreState($input: RestoreStateInput!) {
    restoreState(input: $input) {
      bets {
        ...bet
      }
      wagers {
        ...wager
      }
    }
  }
`;

export const getNextOutcomesGql = gql`
  ${BET}
  query getNextOutcomes($input: RestoreStateInput!) {
    nextOutcomes(input: $input) {
      ...bet
    }
  }
`;

export const getBalanceGql = gql`
  ${USER_BALANCE}
  query GetBalance {
    balance {
      ...userBalance
    }
  }
`;

export const slotHistoryGql = gql`
  ${WAGER}
  ${BET}
  ${OUTCOME}
  query slotHistory($input: BetHistoryInput!) {
    betHistory(input: $input) {
      bets {
        ...bet
      }
      count
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
`;

export const getGameSettingsGql = gql`
  query gameSettings($input: GameSettingsInput!) {
    gameSettings(input: $input) {
      slotId
      clientSlotSettings {
        betCoinAmountSettings
        quickBetCoinAmountSettings
        coinValueSettings {
          code
          variants
        }
        autoplaySettings {
          options
          conditions {
            stopOnAnyWin {
              enabled
            }
            stopIfFeatureIsWon {
              enabled
            }
            stopIfSingleWinExceeds {
              enabled
              multipliers
            }
            stopIfBalanceDecreasesBy {
              enabled
            }
            stopIfBalanceIncreasesBy {
              enabled
              multipliers
            }
          }
        }
      }
      slotSettings {
        ... on TumblingReelSlotSettings {
          cols
          rows
          startingPositions
          betLines {
            min
            max
          }
          globalCoinAmountMultiplier
          buyFeaturePackages {
            packageId
            coinAmountMultiplier
            rounds
          }
        }
      }
    }
  }
`;

export const stressfulGql = gql`
  query Stressful {
    stressful @client
  }
`;

export const configGql = gql`
  query Config {
    isAuthorized @client
    isMobile @client
    isTurboSpin @client
    isSoundOn @client
    isSpinInProgress @client
    isSlotBusy @client
    isSlotStopped @client
    isOpenBetSettingsPopup @client
    isOpenAutoplayPopup @client
    isOpenInfoPopup @client
    isOpenHistoryPopup @client
  }
`;

export const getProgressGql = gql`
  query GetProgress {
    progress @client
  }
`;

export const getBetAmountGql = gql`
  query GetBet {
    betAmount @client
  }
`;

export const getWinAmountGql = gql`
  query GetWin {
    winAmount @client
  }
`;

export const getIsSlotStoppedGql = gql`
  query IsSlotStopped {
    isSlotStopped @client
  }
`;

export const getAutoSpinsSettingsGql = gql`
  query GetAutoSpinsSettings {
    autoSpinsAmount @client
    stopOnWinExceeds @client
    stopOnBalanceIncrease @client
    stopOnBalanceDecrease @client
    isStopOnAnyWin @client
    isStopOnFeatureWin @client
    isStopOnWinExceeds @client
    isStopOnBalanceIncrease @client
    isStopOnBalanceDecrease @client
    autoSpinsStartBalance @client
  }
`;

export const getAutoSpinsGql = gql`
  query GetAutoSpin {
    isAutoSpins @client
    autoSpinsLeft @client
  }
`;

export const getGameModeGql = gql`
  query GetAutoSpin {
    gameMode @client
  }
`;

export const getSlotConfigGql = gql`
  query GetSlotConfig {
    slotConfig @client {
      id
      lines
    }
  }
`;

export const isStoppedGql = gql`
  query IsStopped {
    isSlotStopped @client
  }
`;
